import React, {useEffect, useState} from 'react';
import '../../../assets/styles/blocks/akt.scss'
import {getOrderList, getOrderSingle, getUserInfo} from "../../utils/api/getUserData";
import {useParams} from "react-router-dom";
import paymentLogo from "../../../assets/img/logo.svg"

const Akt = () => {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [loaded, setLoaded] = useState(false);
    const {aktId} = useParams()

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentDate(new Date());
        }, 1000);

        return () => clearInterval(timer);
    }, []);
    const [userData, setUserData] = useState({})
    const [orderDetails, setOrderDetails] = useState({});
    useEffect(() => {
        getUserInfo().then(data => setUserData(data)).catch(error => console.error('Get user error: ', error)).finally(() => setLoaded(true))
        getOrderSingle(aktId).then(data => setOrderDetails(data)).catch(error => console.error('Order list error: ', error)).finally(() => setLoaded(true))
    }, [])
    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentDate(new Date());
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // getMonth() 0 dan boshlanadi, shuning uchun +1 qo'shamiz
    const day = String(currentDate.getDate()).padStart(2, '0');
    const hours = String(currentDate.getHours()).padStart(2, '0');
    const minutes = String(currentDate.getMinutes()).padStart(2, '0');

    useEffect(() => {
        if (loaded) {
            const timer = setTimeout(() => {
                window.print();
            }, 1000);

            return () => clearTimeout(timer);
        }
    }, [loaded]);

    useEffect(() => {
        console.log(orderDetails)
    }, [loaded])

    return (<body style={{padding: '20px'}} className='main__akt_element'>
        <div className="" style={{backgroundColor: 'white', padding: '20px', borderRadius: '12px'}}>
            <section className="user d-flex justify-content-between flex-wrap">
                <div className="user__info">
                    <p><strong>Ismi :</strong> <span>{userData.name?.split(' ')[0]}</span></p>
                    <p><strong>Familyasi :</strong> <span>{userData.name?.split(' ')[1]}</span></p>
                    <p><strong>Otasining ismi :</strong>
                        <span>{userData.name?.split(' ')[2]} {userData.name?.split(' ')[3]}</span></p>

                    <p><strong>Pasport seriyasi :</strong> <span>{userData.passport}</span></p>
                    <p><strong>Sana vaqti :</strong> <span> {day}.{month}.{year} {hours}.{minutes}</span></p>

                </div>
                <div className="user__image">
                    <img width="150" src={userData.avatar} alt="user image"/>
                </div>
            </section>
            <main>
                <section className="act-body">
                    <table border="1" cellspacing="0" cellpadding="5">
                        <thead>
                        <tr>
                            <th>ONLINE-MIKROQARZ shartnomasi <br/> ELEKTRON TAKLIF</th>
                            <th>Договор ОНЛАЙН-ЗАЙМА <br/> ЭЛЕКТРОННАЯ ОФЕРТА</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td valign="baseline">
                                <strong>1-§. Elektron taklif mavzusi</strong> <br/>
                                <strong>1. Ushbu elektron taklifda quyidagi atamalar qo'llaniladi:</strong>

                                <p>Mas'uliyati cheklangan jamiyat shaklidagi
                                    mikromoliya tashkiloti <strong>"ASAXIY UZ MCHJ"</strong>
                                    (keyingi o‘rinlarda mikromoliya tashkiloti deb
                                    yuritiladi) – <strong>"ASAXIY Uz MCHJ"</strong> (keyingi
                                    o‘rinlarda Jamiyat deb yuritiladi) bilan hamkorlik
                                    qiluvchi yuridik shaxs bo‘lib,Hamkorlarning
                                    tovarlarini sotib olish,ish va xizmatlar uchun haq
                                    to‘lash uchun jismoniy shaxslarga qisqa muddatli
                                    onlayn mikrokreditlar taqdim etadi.Asaxiy Nasiya
                                    tizimi;</p>
                                <p><strong>Platforma</strong> – “Asaxiy NASIYA” tizimi, Platforma,
                                    Kompaniya, Mikromoliya tashkiloti yoki elektron
                                    pul tizimining agenti, Mijoz va Mijoz oʻrtasida
                                    oʻzaro hamkorlik va axborot almashinuvini
                                    taʼminlaydigan tashkiliy, axborot va texnik
                                    yechimlarning dasturiy-texnik kompleksi.Hamkor,
                                    Hamkorlar tarmog'i doirasida tovarlar, ishlar va
                                    xizmatlar uchun keyinchalik to'lash bilan
                                    mikrokredit olish uchun.</p>
                                <p><strong>Mikroqarz shartnomasi</strong> - to'lov uchun taqdim
                                    etilgan Hamkorlardan sotib olingan tovarlarni,
                                    ishlarni va xizmatlarni qaytarish muddatini,
                                    imkoniyatini hisobga olgan holda, mijozning to'lov
                                    qobiliyatining Jamiyat tomonidan baholanishi
                                    asosida mikromoliya tashkiloti tomonidan
                                    qarzdor/mijozga ajratiladigan mablag'lar;</p>
                                <p><strong>Qarzdor – Mijoz</strong> – Hamkorlardan onlayn kredit
                                    evaziga tovarlar sotib oluvchi, O‘zbekiston
                                    Respublikasi qonunchiligida belgilangan tartibda
                                    raqamli identifikatsiyadan (raqamli
                                    autentifikatsiya) o‘tgan, tijorat banklaridan biri
                                    tomonidan chiqarilgan bank kartasiga ega bo‘lgan
                                    jismoniy shaxs. O‘zbekiston Respublikasi
                                    banklari;</p>
                                <p><strong>Vositachilik komissiyasi</strong> – Kompaniyaning
                                    Qarzdor-mijozdan elektron hamyonlardan
                                    foydalanish, “Asaxiy Nasiya” tizimi doirasida
                                    to‘lovlarni amalga oshirish, mikrokredit
                                    shartnomalarini rasmiylashtirish va “Asaxiy
                                    Nasiya” tizimining boshqa funktsiyalari uchun
                                    olinadigan daromadi. Vositachilik komissiyasi har
                                    bir onlayn-kreditning elektron taklifida aks
                                    ettirilmasdan (chegirilib) hisobdan olinadi;</p>
                                <p><strong>Qarzdor/mijoz</strong> - ushbu ommaviy ofertada
                                    nazarda tutilgan shartlarda Asaxiy Nasiya tizimi
                                    Hamkorlaridan tovarlarni sotib olish, ish va
                                    xizmatlar uchun haq to‘lash uchun “Asaxiy Nasiya”
                                    tizimi orqali onlayn mikrokredit olgan jismoniy
                                    shaxslar;</p>
                                <p><strong>Hamkor</strong> – “Asaxiy Nasiya” tizimi orqali sotilgan
                                    tovarlar, ishlar va ko‘rsatilgan xizmatlar uchun
                                    to‘lovlarni o‘zining o‘rtacha oylik daromadi
                                    miqdorida majburiyatlarini qabul qilish maqsadida
                                    “Asaxiy Nasiya” tizimida ro‘yxatdan o‘tgan yuridik
                                    shaxs (yoki yuridik shaxs tashkil etmagan yakka
                                    tartibdagi tadbirkor).</p>
                                <p><strong>O'rtacha oylik to'lov summasi</strong> - barcha qarz
                                    oluvchilar bilan tuzilgan va tuzilishi mo'ljallangan
                                    shartnomalarga muvofiq qarz oluvchi/mijoz
                                    tomonidan amalga oshirilgan oylik to'lovlarning
                                    o'rtacha arifmetik ko'rsatkichi, shuningdek, qarz
                                    oluvchi/mijoz kafil sifatida ishtirok etadigan
                                    bitimlar.</p>
                                <p><strong>O'rtacha oylik daromad</strong> - qarz oluvchi/mijoz
                                    tomonidan so'nggi 12 oy davomida ish haqi
                                    loyihasi asosida chiqarilgan bank kartasi orqali
                                    olingan daromadning elektron taklif aks ettirilgan
                                    sanaga nisbatan o'rtacha arifmetik ko'rsatkichi
                                    (agar 12 oydan kam ishlagan bo'lsa). oylar,
                                    amalda ishlab chiqilgan muddat kamida 6 oy );</p>
                                <p><strong>Shaxsni tasdiqlovchi hujjat</strong> - Oʻzbekiston
                                    Respublikasi fuqarosining pasporti yoki shaxsiy
                                    guvohnomasi, Oʻzbekiston Respublikasida doimiy
                                    yashovchi chet el fuqarosi va fuqaroligi boʻlmagan
                                    shaxsning yashash uchun ruxsatnomasi yoki
                                    shaxsini tasdiqlovchi hujjat.</p>
                                <p>2. Qarzdor/mijoz elektron taklif shartnomasini
                                    imzolagandan so‘ng mikromoliya tashkilotida
                                    qarzdor/mijoz nomiga onlayn qarz hisobvarag‘i
                                    ochiladi va qarzdor/mijozga ajratilgan onlayn
                                    mikrokredit summasi kreditga o‘tkaziladi. ushbu
                                    hisobdan elektron hamyon.</p>
                                <p>3. Elektron taklif asosida mikromoliya tashkiloti
                                    tomonidan o‘tkazilgan mablag‘lar qarz
                                    oluvchi/mijoz uchun onlayn kredit sifatida qayta
                                    ishlanadi.</p>


                                <strong>2-§. Onlayn kredit shartlari</strong> <br/>

                                <p>4. Onlayn kredit 6 (olti) oygacha bo'lgan
                                    muddatga taqsimlanadi.
                                    5. Onlayn mikrokreditning maksimal miqdori 4 811 940,00 (to'rt million sakkiz
                                    yuz o'n bir
                                    ming
                                    to'qqiz yuz qiriq) so‘mdan oshmaydi.
                                    6. Mikromoliya tashkiloti onlayn-kreditni
                                    taqsimlashda hududiy cheklov qo‘yishi mumkin.
                                    7. Onlayn mikroqarz qarz oluvchi/mijoz o'rtasida
                                    Kompaniyaning skoring asosida taqsimlanadi.
                                    8.Skorlash jarayonida Mikromoliya tashkiloti
                                    quyidagi holatlardan biri mavjud bo‘lganda qarz
                                    oluvchi/mijozga onlayn mikroqarz bermaslik
                                    huquqiga ega:
                                    a) ilgari olingan kreditlar va boshqa majburiyatlar
                                    bo'yicha muddati o'tgan qarz mavjud bo'lsa;
                                    b) "Soliq xizmati" DUK negizida qarzdor/mijozning
                                    daromadi 6 oydan kam bo'lsa;
                                    v) “Soliq xizmati” DUK negizida onlayn-kredit olish
                                    uchun ariza topshirish oyiga 2 oy qolganda
                                    qarzdor/mijozning daromadlari to‘g‘risida
                                    ma’lumot bo‘lmaganda;
                                    g) agar qarz oluvchi/mijozning so'rovi bo'yicha
                                    chiqarilgan bank kartasi oxirgi 2 oy ichida kreditga
                                    o'tkazilmagan bo'lsa;
                                    g) qarzdor/mijoz 22 yoshdan 65 yoshgacha
                                    bo'lgan yosh chegarasiga kirmasa;
                                    d) kredit yuki 50 foizdan oshgan taqdirda, onlaynkreditning qiymati ham
                                    hisob-kitobga
                                    qo'shiladi.
                                    9. Qarz oluvchi/mijoz 6 (olti) oy davomida
                                    tasdiqlangan onlayn kredit limitidan foydalanishi
                                    mumkin. Tasdiqlangan limitning amal qilish
                                    muddati limitni tasdiqlash to'g'risida SMS-xabar
                                    yuborilgan paytdan boshlab hisoblanadi.
                                    Tasdiqlangan limit, uning qancha qismini
                                    ishlatmasin, bir marta foydalanishdan keyin o‘tgan
                                    hisoblanadi.
                                    10. Foydalanilgan onlayn mikrokredit uchun qarz
                                    oluvchi/mijozdan foizlar ko'rinishidagi to'lov
                                    undirilmaydi.
                                    11. Onlayn mikroqarz qarz oluvchi/mijoz
                                    tomonidan onlayn kredit to‘lash jadvaliga muvofiq
                                    to‘lanadi.
                                    12. Onlayn mikrokredit mikrokredit liniyasi
                                    ko‘rinishida mikrokreditning bir qismini to‘lash
                                    vaqtida aylanma qoldiq bilan 6 (olti) oy muddatga
                                    ajratiladi.
                                </p>


                                <strong>3-§. Tomonlarning huquq va majburiyatlar</strong> <br/>
                                <strong>13. Mikromoliya tashkilotining huquqlari:</strong>

                                <p>a) mikrokreditni onlayn to'lash muddatiga
                                    kelganda, qarz oluvchi/mijozning bank kartasi
                                    hisobvarag'idan kreditni hisobdan chiqarish;
                                    b) agar onlayn mikroqarz belgilangan muddatda
                                    to'lanmagan bo'lsa (qarz summasini to'lamagan
                                    yoki belgilangan muddatda qarzni to'lagan bo'lsa),
                                    uni qaytarish uchun hisobvaraqlar va bank
                                    kartalaridagi mablag'lar bo'yicha undirib olinadi.
                                    shartnomani imzolash vaqtida mavjud bo‘lgan,
                                    shuningdek, qarzdor/mijoz nomiga ochilgan
                                    O‘zbekiston Respublikasining boshqa banklarida
                                    keyinroq ochilgan, tegishli ma’lumotlarni kiritib,
                                    ushbu elektron ofertada ko‘rsatilgan choralarni
                                    ko‘radi;
                                    v) qarz oluvchini/mijozni kreditni onlayn to'lash
                                    muddati buzilgan taqdirda nizolarni hal qilish
                                    uchun ariza to'g'risida xabardor qilish;
                                    e) kreditning belgilangan muddatda
                                    qaytarilmasligi natijasida onlayn mikroqarz
                                    sug‘urta kompaniyasi tomonidan qoplangan
                                    taqdirda, mikrokredit bo‘yicha qarzni onlayn
                                    undirish huquqini sug‘urta kompaniyasiga
                                    o‘tkazish.</p>

                                <strong>14. Mikromoliya tashkilotining majburiyatlari:</strong>

                                <p>a) ushbu elektron taklifda ko'rsatilgan shartlar
                                    asosida onlayn mikroqarz olish uchun ariza
                                    berish;
                                    b) qarz oluvchini/mijozni onlayn mikroqarzni
                                    muddatidan oldin to'lash sabablari to'g'risida
                                    xabardor qilish;
                                    v) qarz oluvchidan/mijozdan onlayn - joriy kredit
                                    to'lovi uchun onlayn kredit to'lash jadvalida
                                    aniqlanadi. Agar nisbatan katta miqdordagi
                                    mablag 'qo'yilgan bo'lsa, unda olingan
                                    summaning ortig'i keyingi oyga qadar kreditni
                                    to'lash uchun ishlatiladi;
                                    e) qarzdor/mijozning kredit yukining yanada
                                    oshishiga yo'l qo'ymaslik maqsadida, onlaynkredit bo'yicha muddati o'tgan
                                    qarzdorlik yuzaga
                                    kelgan taqdirda, har qanday aloqa vositalaridan,
                                    shu jumladan elektron aloqa vositalaridan
                                    foydalangan holda qarzdor/mijozni muddati o'tgan
                                    qarzdorlik yuzaga kelganligi to'g'risida xabardor
                                    qilish. , ko'rsatgan holda, ushbu qarz olish sodir
                                    bo'lgan kundan boshlab 7 kalendar kun ichida;
                                    f) qarzdorni/mijozni holati, amal qilish muddati,
                                    qiymati, tarkibi va ushbu elektron oferta bo'yicha
                                    muddati o'tgan qarzni to'lash majburiyatini
                                    bajarmaslik oqibatlari to'g'risida xabardor qilish;
                                    i) qarzdor/mijoz onlayn-kredit bo‘yicha kredit to‘liq
                                    to‘langanidan so‘ng qarzdor/mijozning shaxsiy
                                    kabinetidagi onlayn-kredit bo‘yicha qarzni to‘liq
                                    to‘laganligi to‘g‘risidagi ma’lumotlarni avtomatik
                                    tarzda shakllantirish.</p>

                                <strong>15. Qarzdor/mijozning huquqlari:</strong>

                                <p>a) ushbu elektron taklifni aks ettirish bo'yicha
                                    mustaqil qaror qabul qilish;
                                    b) onlayn kreditni o'z vaqtida taqsimlashni talab
                                    qilish;
                                    c) istalgan vaqtda onlayn kredit to'liq
                                    to'langanidan keyin ushbu elektron taklifni
                                    muddatidan oldin bekor qilish. </p>

                                <strong>16. Qarzdor/mijozning majburiyatlari:</strong>

                                <p>a) onlayn - kreditni o'z vaqtida to'lash;
                                    b) taklif bilan tanishish;
                                    v) shaxsiy ma'lumotlar, ro'yxatdan o'tish manzili,
                                    telefon raqami va pul mablag'larini yig'ish tartibini
                                    amalga oshirish uchun zarur bo'lgan boshqa
                                    ma'lumotlar o'zgarganligi to'g'risida to'lov jadvali
                                    yuzaga kelganda darhol Kompaniyani va
                                    mikromoliya tashkilotini xabardor qilish;</p>


                                <strong>4-§. Tomonlarning javobgarligi.</strong> <br/>

                                <p>17. Tomonlar ushbu Elektron oferta bo‘yicha o‘z
                                    majburiyatlarini bajarmagan yoki zarur darajada
                                    bajarmagan taqdirda, ularga O‘zbekiston
                                    Respublikasi qonun hujjatlarida nazarda tutilgan
                                    javobgarlik choralari qo‘llaniladi.</p>


                                <strong>5-§. Favqulodda vaziyat.</strong> <br/>

                                <p>18. Tomonlar fors-major holatlari amal qilgan
                                    davrda Elektron oferta bo‘yicha o‘z
                                    majburiyatlarini qisman yoki to‘liq
                                    bajarmaganliklari uchun javobgar emaslar. Eng
                                    muhim holatlarga quyidagilar kiradi: tabiiy
                                    hodisalar (zilzilalar, ko'chkilar, qurg'oqchilik va
                                    boshqa tabiat hodisalari) yoki ijtimoiy-iqtisodiy
                                    vaziyatlar (iqtisodiy sanksiyalar, harbiy harakatlar,
                                    ish tashlashlar, qamallar, davlat tashkilotlari va
                                    davlat tashkilotlari o'rtasidagi cheklovchi va
                                    taqiqlovchi choralar) natijasida yuzaga kelgan
                                    favqulodda vaziyatlar; hukumat qarorlari va
                                    boshqalar), agar bu holatlar elektron taklif
                                    bo'yicha shartlarning bajarilishiga bevosita ta'sir
                                    etsa, joriy sharoitda bartaraf etilishi mumkin
                                    bo'lmagan kutilmagan holatlar va kutilmagan
                                    holatlar. Fors-major holatlari yuzaga kelganda va
                                    bu to'xtatilganda, tomonlar darhol bir-birlarini
                                    xabardor qilishlari kerak. Xabarnoma tomonlar
                                    uchun mavjud bo'lgan barcha aloqa vositalari
                                    orqali yuboriladi.
                                    Fors-major holatlari bilan bog'liq holatlar yuzaga
                                    kelgan taqdirda, tomonlarning majburiyatlarini
                                    bajarish muddati fors-major holatlari bilan bog'liq
                                    holatlar amal qilgan muddatga mutanosib
                                    ravishda kechiktiriladi.</p>


                                <strong>6-§. Nizolarni hal qilish.</strong> <br/>

                                <p>19. Mikromoliya tashkiloti va qarzdor/mijoz
                                    taraflarning o‘zaro kelishuviga ko‘ra nizolarni
                                    qonun hujjatlarida belgilangan, shu jumladan
                                    muzokaralar yo‘li bilan hal qilish usullarini
                                    qo‘llashga haqli.
                                    20. Majburiyatlarni bajarmaslik yoki lozim
                                    darajada bajarmaslik bilan bog'liq barcha nizolarni
                                    Tomonlar muzokaralar va da'volarni taqdim etish
                                    jarayonida hal qilishga harakat qiladilar. Da'voni
                                    ko'rib chiqish muddati da'voni oluvchiga da'vo
                                    yuborilgan kundan boshlab 3 kalendar kun.
                                    21. Muzokaralar davomida kelishuvga
                                    erishilmaganda va da’vo tartibiga rioya qilgan
                                    holda, nizolar O‘zbekiston Respublikasining
                                    amaldagi qonunchiligiga muvofiq Mikromoliya
                                    tashkiloti joylashgan (shartnoma yurisdiktsiyasi)
                                    bo‘yicha sud tartibida hal etiladi. O‘zbekiston
                                    Respublikasi davlat sudi.</p>


                                <strong>7-§. Elektron taklifning boshqa shartlari</strong> <br/>

                                <p> 22. Qarz oluvchi/mijoz Elektron taklifni ko‘rsatish
                                    orqali uning shaxsiga oid ma’lumotlarni qayta
                                    ishlashga rozi bo‘lgan deb hisoblanadi.
                                    23. Mikromoliya tashkiloti ushbu elektron taklifdan
                                    foydalangan holda qarz oluvchi/mijoz uchun
                                    onlayn-kredit uchun onlayn tarzda to‘lash
                                    imkoniyatini yaratadi.
                                    24. Mikromoliya tashkiloti onlayn-kredit shartlarini,
                                    shu jumladan ushbu elektron taklifning amal qilish
                                    muddatini bir tomonlama tartibda o‘zgartirishga
                                    haqli emas.
                                    25. Qarzdor/mijoz vafot etgan taqdirda uning
                                    huquq va majburiyatlari O‘zbekiston Respublikasi
                                    qonun hujjatlariga muvofiq hal etiladi.
                                    26. Mazkur elektron oferta qarzdor/mijoz
                                    tomonidan imzolangan kundan boshlab ushbu
                                    elektron oferta bo‘yicha majburiyatlar to‘liq
                                    bajarilgunga qadar amal qiladi.
                                    27. Ushbu Elektron ofertaning bekor qilinishi
                                    tomonlarni u to‘xtatilgunga qadar bildirilgan o‘zaro
                                    talabni (talabni) qondirish majburiyatidan ozod
                                    etmaydi.
                                    28. Mazkur elektron ofertada nazarda tutilmagan
                                    hollarda O‘zbekiston Respublikasi qonun hujjatlari
                                    qo‘llaniladi. Vositachilik komissiyasining to‘lovi
                                    oshkor etilmagan. Erta yig'ish tartibi (61 kun
                                    davomida shartnoma bo'yicha kechikish bo'lsa,
                                    biz butun shartnomani muddatidan oldin undirish
                                    huquqiga egamiz) . </p>
                            </td>
                            <td valign="baseline">
                                <strong>1-§. Предмет электронной оферты</strong> <br/>
                                <strong>1. В настоящей электронной оферте используются следующие понятия:</strong>

                                <p>Микрофинансовая организация в форме
                                    общества с ограниченной ответственностью
                                    "ASAXIY UZ MCHJ" (далее –
                                    микрофинансовая организация) является
                                    юридическим лицом, которое сотрудничает с
                                    "ASAXIY Uz MCHJ" (далее Общество) , по
                                    предоставлению краткосрочных онлайн
                                    микрозаймов физическим лицам для
                                    приобретения товаров, оплаты работ и услуг
                                    Партнеров системы Asaxiy Nasiya;</p>
                                <p><strong>Платформа</strong> – система Asaxiy Nasiya,
                                    программно-аппаратный комплекс
                                    организационных, информационных и
                                    технических решений, обеспечивающие
                                    взаимодействие и обмен информацией между
                                    Платформой, Обществом, Микрофинансовой
                                    организации или агентом системы
                                    электронных денег, Клиента и Партнером, в
                                    целях получения микрозайма с последующей
                                    оплатой за товары, работы и услуги в рамках
                                    Партнерской сети.</p>
                                <p><strong>Договор микрозайма</strong> - денежные средства,
                                    выделенные микрофинансовой организацией
                                    должнику/клиенту на основании проведенного
                                    скоринга платежеспособности клиента
                                    Обществом с учетом срока, возможности
                                    возврата предусмотренных для оплаты
                                    товаров, работ и услуг приобретенных у
                                    Партнеров;</p>
                                <p><strong>Должник – клиент</strong> - физическое лицо,
                                    покупающее товары у Партнеров за онлайнзайм, прошедшее цифровую идентификацию
                                    (цифровую аутентификацию) в соответствии с
                                    процедурой, установленной
                                    законодательством Республики Узбекистан,
                                    владеющее банковской картой, выпущенной
                                    одним из коммерческих банков Республики
                                    Узбекистан;</p>
                                <p><strong>Посредническая комиссия</strong> - это доход
                                    Общества, взимаемый с Должника-клиента за
                                    использование электронных кошельков,
                                    проведение платежей в рамках Asaxiy Nasiya, обработку договоров микрозаймов и
                                    прочие
                                    функции системы Asaxiy Nasiya .
                                    Посредническая комиссия взимается со счета,
                                    без отражения в электронном предложении
                                    каждого онлайн-займа (вычитается);</p>
                                <p><strong>Должник/клиент</strong> - физические лица
                                    получившие через систему Asaxiy Nasiya
                                    онлайн микрозайм для приобретения товаров,
                                    оплаты работ и услуг у Парнеров системы
                                    Asaxiy Nasiya на условиях предусмотренных
                                    настоящей публичной офертой..;</p>
                                <p><strong>Партнер</strong> - юридическое лицо (или
                                    индивидуальный предприниматель без
                                    образования юридического лица),
                                    зарегистрированное в системе Asaxiy Nasiya с
                                    целью приемки платежей за проданный товар,
                                    оказанные работы и услуги через систему
                                    Asaxiy Nasiya. <strong>показатель займовой нагрузки</strong>
                                    – отношение среднемесячной суммы платежа
                                    заемщика/клиента по займным и другим
                                    обязательствам к сумме его среднемесячного
                                    дохода;</p>
                                <p><strong>Среднемесячная сумма платежа</strong> - представляет собой среднее
                                    арифметическое
                                    значение ежемесячных платежей,
                                    произведенных заемщиком/клиентом в
                                    соответствии с соглашениями, заключенными
                                    и предназначенными для заключения со всеми
                                    заемщиками, а также сделками, в которых
                                    заемщик/клиент участвует в качестве гаранта;</p>
                                <p><strong>Среднемесячный доход</strong> - — это среднее
                                    арифметическое значение дохода,
                                    полученного заемщиком/клиентом через
                                    банковскую карту, выпущенную на основе
                                    зарплатного проекта за последние 12 месяцев
                                    по сравнению с датой отражения
                                    Электронного предложения (если проработал
                                    менее 12 месяцев, период, отработанный в
                                    действительности, не менее 6 месяцев);</p>
                                <p><strong>Документ, удостоверяющий личность</strong> - паспорт или удостоверение
                                    личности
                                    гражданина Республики Узбекистан, вид на
                                    жительство или удостоверение личности
                                    иностранного гражданина и лица без
                                    гражданства, постоянно проживающего в
                                    Республике Узбекистан.
                                    2. После того, как должник/клиент подпишет
                                    соглашение об электронной оферте, в
                                    микрофинансовой организации будет открыт
                                    счет онлайн-задолженности на имя
                                    должника/клиента, и с этого счета на
                                    электронный кошелек будет зачислена сумма
                                    онлайн-микрозайма, выделенная
                                    должнику/клиенту.
                                    3. На основании электронного предложения
                                    денежные средства, переведенные
                                    микрофинансовой организацией, оформляются как онлайн-займ
                                    заемщику/клиенту.</p>


                                <strong>2-§. Условия Онлайн-займа</strong> <br/>

                                <p>4. Онлайн-займ распределяется на срок до 6 (шесть) месяцев.
                                    5. Максимальная сумма онлайн микрозайма не
                                    превышает 4 811 940,00 (четыре миллиона восемьсот одиннадцать тысяч девятьсот
                                    сорок) сум.
                                    6. Микрофинансовая организация может
                                    наложить территориальное ограничение на
                                    распределение онлайн-займа.
                                    7. Онлайн микрозайзайм распределяется
                                    между заемщиком/клиентом на основе
                                    проведенного скоринга Обществом.
                                    8. В процессе скоринга Микрофинансовая
                                    организация имеет право не выделяется
                                    заемщику/клиенту онлайн микрозайм при
                                    наличии одной из следующих ситуаций:
                                    а) при наличии просроченной задолженности
                                    по ранее полученным займам и другим
                                    обязательствам;
                                    Г) когда доход должника/клиента на базе ГУП
                                    "Налоговая служба" составляет менее 6
                                    месяцев;
                                    д) при отсутствии информации о доходах
                                    должника/клиента за 2 месяца до месяца
                                    подачи заявки на онлайн-займа на базе ГУП
                                    "Налоговая служба";
                                    е) если банковская карта, выпущенная для
                                    обращения заемщика/клиента, не была
                                    зачислена за последние 2 месяца;
                                    ж) когда должник/клиент не попадает в
                                    возрастной диапазон от 22 до 65 лет;
                                    з) в том случае, если показатель займовой
                                    нагрузки превышает 50 процентов, при
                                    расчете также добавляется стоимость онлайнзайма.
                                    9. Заемщик/клиент может использовать
                                    утвержденный лимит онлайн-займа в течение
                                    6 (шесть) месяцев. Срок действия утвержденного
                                    лимита рассчитывается с момента отправки
                                    SMS-уведомления об утверждении лимита.
                                    Утвержденный лимит, независимо от того,
                                    сколько его частей они используют, считается
                                    утратившим свою силу после одного
                                    использования.
                                    10. За использованный онлайн микрозайма
                                    оплата в виде процентов с заемщика/клиента
                                    не взимается.
                                    11. Онлайн микрозайм возвращается
                                    заемщиком/клиентом в соответствии с
                                    графиком погашения онлайн-займа.
                                    12. Онлайн микрозайм выделяется в виде
                                    микро займовой линии на 6 (шесть) месяцев с
                                    возобновляемым остатком в момент
                                    погашения части микрозайма.</p>


                                <strong>3-§. Права и обязанности сторон</strong> <br/>
                                <strong>13. Права микрофинансовой организации:</strong>

                                <p>а) когда дело доходит до срока онлайн-оплаты
                                    микрозайма, списать займ со счета банковской
                                    карты заемщика / клиента;
                                    б) при невозврате онлайн-микрозайма в
                                    установленный срок (невозврат суммы займа
                                    или погашение задолженности в
                                    установленный срок), для его возврата
                                    направить взыскание на средства,
                                    находящиеся на счетах и банковских картах
                                    имевшихся на момент подписания договора, а
                                    так же открытых позже в других банках
                                    Республики Узбекистан, открытых на имя
                                    должника/клиента, внести соответствующие
                                    сведения и принять меры, указанные в этой
                                    электронной оферте;
                                    в) информировать заемщика/клиента о заявке
                                    на урегулирование спора в случае нарушения
                                    срока погашения онлайн-займа;
                                    д) передача права онлайн-взыскания
                                    задолженности по микрозайму страховой
                                    организации, когда онлайн микрозайм
                                    покрывается страховой организацией в
                                    результате невозврата займа в течение
                                    указанного периода.</p>

                                <strong>14. Обязательства микрофинансовой организации:</strong>

                                <p>а) оформить онлайн микрозайм на условиях,
                                    изложенных в настоящем электронном
                                    предложении;
                                    б) информировать заемщика/клиента о
                                    причинах досрочного погашения онлайнмикрозайма;
                                    в) от заемщика/клиента онлайн - определяется
                                    в онлайн-графике погашения
                                    займа для текущего платежа по займу. Если
                                    вносится относительно большая сумма
                                    средств, то превышение полученной суммы
                                    направляется на погашение займа к
                                    следующему месяцу;
                                    д) в целях предотвращения дальнейшего
                                    увеличения займовой нагрузки на
                                    должника/клиента в случае возникновения
                                    просроченной задолженности по онлайнзайму проинформировать должника/клиента о
                                    возникновении просроченной задолженности с
                                    использованием любых средств связи,
                                    включая электронную связь, в течение 7
                                    календарных дней с момента дата
                                    возникновения этого заимствования, с
                                    указанием;
                                    е) информировать должника/клиента о
                                    статусе, сроке действия, стоимости, составе и
                                    последствиях неисполнения обязательства по
                                    погашению непогашенной задолженности,
                                    которая была просрочена по данному
                                    электронному предложению;
                                    и) автоматическое формирование
                                    информации о том, что должник/клиент
                                    полностью погасил задолженность по онлайнзайму в личном кабинете
                                    должника/клиента
                                    после полной оплаты займа по онлайн-займу</p>

                                <strong>15. Права должника/клиента:</strong>

                                <p>а) принять независимое решение об
                                    отражении данного электронного
                                    предложения;
                                    б) требовать своевременного распределения
                                    онлайн-займа;
                                    в) досрочно отменить данное электронное
                                    предложение после полной оплаты онлайнзайма в любое время</p>

                                <strong>16. Обязательства должника/клиента:</strong>

                                <p>а) онлайн - своевременное погашение займа;
                                    б) ознакомится с офертой;
                                    в) незамедлительно информировать
                                    Общество и микрофинансовую организацию о
                                    смене персональных данных, адреса
                                    прописки, номере телефона и иных данных
                                    необходимых для исполнения процедуры
                                    взыскания средств при наступлении графика
                                    платежей; </p>


                                <strong>4-§. Ответственность сторон</strong> <br/>

                                <p>17. В случае, если стороны невыполняют
                                    обязательства по настоящей Электронной
                                    Оферте или не выполняют их на требуемом
                                    уровне, к ним применяются меры
                                    ответственности, предусмотренные
                                    законодательством Республики Узбекистан.</p>


                                <strong>5-§. Форс-мажор</strong> <br/>

                                <p>18. Стороны не несут ответственность за
                                    частичное или полное неисполнение своих
                                    обязательств по Электронной Оферте в
                                    период, в течение которого действует форсмажор.
                                    К наиболее важным обстоятельствам
                                    относятся: чрезвычайные ситуации,
                                    вызванные природными явлениями
                                    (землетрясения, оползни, засухи и другие
                                    природные явления) или социальноэкономические ситуации (экономические
                                    санкции, военные действия, забастовки,
                                    осады, ограничительные и запретительные
                                    меры между государственными организациями
                                    и государственными организациями, решения
                                    правительства и т.д.), непредвиденные случаи
                                    и непредвиденные ситуации которые не могут
                                    быть устранены в текущих условиях, если эти
                                    ситуации оказывают непосредственное
                                    влияние на выполнении условий по
                                    электронной оферте.
                                    Когда возникают ситуации форс-мажорные
                                    ситуации, и когда это прекращается, стороны
                                    немедленно информируют друг друга.
                                    Уведомление направляется всеми доступными
                                    для сторон средствами связи.
                                    В случае наступления обстоятельств,
                                    связанных с форс-мажором, у сторон срок
                                    исполнения обязательств откладывается
                                    пропорционально периоду, на который
                                    действительны случаи, связанные с форсмажорами.</p>


                                <strong>6-§. Разрешение споров</strong> <br/>

                                <p>19. Микрофинансовая организация и
                                    должник/клиент, по взаимному согласию
                                    сторон, вправе применять установленные
                                    законом методы разрешения спора, в том
                                    числе путем переговоров.
                                    20. Все споры, связанные с неисполнением,
                                    или ненадлежащим исполнением
                                    обязательств, Стороны будут стараться
                                    решить в ходе переговоров и направления
                                    претензий. Срок рассмотрения претензии
                                    составляет 3 календарных дня с даты
                                    направления претензии в адрес получателя
                                    претензии.
                                    21. В случае не достижения согласия в ходе
                                    переговоров и при соблюдении
                                    претензионного порядка, споры будут
                                    разрешаться в судебном порядке в
                                    соответствии с действующим
                                    законодательством Республики Узбекистан по
                                    месту нахождения Микрофинансовой
                                    организации (договорная подсудность),
                                    уполномоченным государственным судом РУз.</p>


                                <strong>7-§. Другие условия электронной оферты</strong> <br/>

                                <p>22. Считается, что заемщик/клиент согласился
                                    обработать информацию, касающуюся его/ее
                                    личности, путем отображения Электронного
                                    Предложения.
                                    23. Микрофинансовая организация создает
                                    возможность для заемщика/клиента удаленно
                                    осуществлять оплату онлайн-займа по
                                    данному электронному предложению.
                                    24. Микрофинансовая организация не имеет
                                    права в одностороннем порядке изменять
                                    условия онлайн- займа, включая срок действия
                                    настоящего электронного предложения.
                                    25. В случае смерти должника/клиента его
                                    права и обязанности разрешаются в
                                    соответствии с законодательством Республики
                                    Узбекистан.
                                    26. Настоящая электронная оферта действует
                                    с даты ее подписания должником/клиентом до
                                    полного выполнения обязательств по
                                    настоящей электронной оферте.
                                    27. Прекращение действия настоящей
                                    Электронной Оферты не освобождает стороны
                                    от обязанности удовлетворить взаимную
                                    претензию (требование), высказанную до тех
                                    пор, пока она не будет прекращена.
                                    28. Законодательство Республики Узбекистан
                                    применяется в случаях, не предусмотренных
                                    настоящей электронной офертой.
                                    Не раскрыто оплата посреднической комиссии.
                                    Процедура досрочного взыскания (на 61 день
                                    имеем право на досрочное взыскание всего
                                    договора при наличии просрочки по договору).
                                </p>


                            </td>
                        </tr>
                        </tbody>
                    </table>
                </section>

                <br/>
                <br/>

                <section className="act-body payments-list" style={{paddingBottom: '30px'}}>
                    <header className="payment-list__header d-flex justify-content-between">
                        <div className="header__logo">
                            <img src="/images/Logo.svg" alt="" width="156"/>
                        </div>
                        <div className="header__info">
                            <p className="gray" style={{color: '#000'}}>Qo'llab-quvvatlash xizmati</p>
                            <p className="gray" style={{margin: '0 0 5px 0'}}>Служба поддержки</p>
                            <h3></h3>
                        </div>
                    </header>

                    <h1 className="payments-list__title" style={{fontSize: '25px', margin: '20px 0 5px 0'}}>
                        To'lash jadvali buyurtma №
                    </h1>
                    <p style={{margin: '0 0 10px 3px'}} className="gray">График оплаты заказа</p>

                    <table style={{border: '#fff', width: '100%'}}>
                        <tr style={{border: '#fff'}}>
                            <td align="right" style={{border: '#fff'}}>
                                Sana: {orderDetails.created_at}
                            </td>
                        </tr>
                    </table>
                    <p align="justify">
                        <table style={{width: '100%'}} align="center">
                            <thead>
                            <tr>
                                <td align="center"><b>№</b></td>
                                <td align="center"><b>Sana</b></td>
                                <td align="center"><b>Oylik to`lov</b></td>
                                <td align="center"><b>To'lov qoldig'i, so'm</b></td>
                            </tr>
                            </thead>
                            <tbody>
                            {loaded && orderDetails.items.map((item, index) => (<tr>
                                <td align='center'>
                                    {index + 1}
                                </td>
                                <td align='center'>
                                    {item.starts_in}
                                </td>
                                <td align='center'>
                                    {item.amount}
                                </td>
                                <td align='center'>
                                    {item.amount - item.paid_amount}
                                </td>
                            </tr>))}
                            <tr>
                                <td align="center" colSpan={2}style={{fontWeight:700}}>Jami</td>
                                <td align="center" style={{fontWeight:700}}>{orderDetails.amount}/{orderDetails.paid_amount}</td>
                                <td align="center" style={{fontWeight:700}}>{orderDetails.saldo_coming}</td>
                            </tr>
                            </tbody>
                        </table>

                        <table style={{border: 'solid #fff !important'}}>
                            <tr style={{border: 'solid #fff'}}>
                                <td align="left" style={{border: 'solid #fff'}}>
                                    <h6 align="center">Topshirdi:</h6>
                                    <p align="justify">
                                        "ASAXIY UZ" МЧЖ
                                        <br/>
                                        ____________
                                    </p>
                                </td>
                                <td style={{border: 'solid #fff', paddingLeft: '150px'}}></td>
                                <td align="right" style={{border: 'solid #fff'}}>
                                    <h6 align="center">Qabul qildi:</h6>
                                    <p align="justify">
                                        <br/>
                                        Imzo: _______________
                                    </p>
                                </td>
                            </tr>
                        </table>
                    </p>
                    <div className="payments-list__client-phone d-flex justify-content-between">
                        <div className="client-phone__text">
                            <h3 style={{margin: '0'}}>To'lov uchun sizning shaxsiy raqamingiz</h3>
                            <p>Номер вашего счета для пополнения</p>
                        </div>
                        <div className="client-phone__number"></div>
                    </div>

                    <div className="payments-list__footer">
                        <div className="footer__info d-flex justify-content-between flex-wrap" style={{height: '40px'}}>
                            <h2 className="footer__info-title" style={{marginTop: '15px', fontSize: '15px'}}>Bank
                                rekvizitlari bo'yicha
                                to'lov</h2>
                            <div className="footer__info-logos">
                                <img src="https://cabinet.paymart.uz/images/logos/paynet.png" width="100px"
                                     alt="paynet"/>
                                <img src="https://cabinet.paymart.uz/images/logos/uzcard.png" width="100px"
                                     alt="uzcard"/>
                                <img src="https://cabinet.paymart.uz/images/logos/upay.png" width="100px"
                                     alt="upay"/>
                            </div>
                        </div>
                        <p className="gray" style={{marginBottom: '30px'}}>Оплата по банковским реквизитам</p>
                        <div className="footer__info-footer d-flex justify-content-between"
                             style={{height: '100px'}}>
                            <div style={{fontSize: '10px'}}>
                                <p><strong> “Asaxiy uz” MChJ"</strong></p>
                                <p>Р/с: <strong>20208000700848256033</strong></p>
                                <p>МФО <strong>00401</strong></p>
                                <p>ИНН <strong>305144814</strong></p>
                                <p>ОКЭД <strong>47410</strong></p>
                            </div>
                            <div style={{paddingTop: '40px'}}>
                                <strong>Telefon raqamingiz yoki ID&nbsp;ni ko'rsatishni unutmang</strong>
                                <p>Не забудьте указать свой номер телефона или ID</p>
                            </div>
                        </div>
                    </div>

                    <section className="pay-example d-flex justify-content-around align-items-center">
                        <div className="payment-type d-flex justify-content-around">
                            <div className="payment-type__payment">
                                <img src={paymentLogo} alt="payment-logo"
                                     style={{width: '150px'}}/>
                                <div className="payment__info">
                                    <strong>Asosiy — Shaxsiy hisobingizni to'ldiring</strong>
                                    <p>Главная — Пополнить&nbsp;лицевой счёт</p>
                                </div>
                                <div className="payment__info">
                                    <strong>To'ldirish orqali — Xaritalar</strong>
                                    <p>Пополнить через —&nbsp;Карты</p>
                                </div>
                                <div className="payment__info">
                                    <strong>To'lov summasi</strong>
                                    <p>Сумма оплаты</p>
                                </div>
                            </div>
                        </div>
                        <div className="payment-type d-flex justify-content-around">
                            <div className="payment-type__payment">
                                <img src="https://cabinet.paymart.uz/images/logos/uzum-bank.png" alt="payment-logo"
                                     width="64"/>
                                <div className="payment__info">
                                    <strong>To'lov — Kategoriya bo'yicha to'lov</strong>
                                    <p>Оплата — Оплата по&nbsp;категориям</p>
                                </div>
                                <div className="payment__info">
                                    <strong>Kreditlar — Asaxiy Rassrochka</strong>
                                    <p>Kreditlar — Asaxiy Rassrochka</p>
                                </div>
                                <div className="payment__info">
                                    <strong>Telefon raqami</strong>
                                    <p>Номер телефона</p>
                                </div>
                            </div>
                        </div>
                        <div className="payment-type d-flex justify-content-around">
                            <div className="payment-type__payment">
                                <img src="https://cabinet.paymart.uz/images/logos/payme.png" alt="payment-logo"
                                     width="64"/>
                                <div className="payment__info">
                                    <strong>To'lov — xizmatiga to'lov</strong>
                                    <p>Оплата — Оплата услуг</p>
                                </div>
                                <div className="payment__info">
                                    <strong>Kredit va bo'lakli to'lovlarni so'ndirish — Asaxiy Rassrochka</strong>
                                    <p>Погашение кредитов и&nbsp;рассрочек — Asaxiy Rassrochка</p>
                                </div>
                                <div className="payment__info">
                                    <strong>Telefon raqami, to'lov summasi</strong>
                                    <p>Ваш номер, сумма&nbsp;платежа</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
            </main>
        </div>
        </body>

    );
};

export default Akt;