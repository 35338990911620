import Cookies from 'js-cookie'

export const getCookie = (name) => {
    if (typeof name !== 'string') {
        console.error('Cookie nomi string bulishi kerak');
        return undefined;
    }
    return Cookies.get(name);
};

export const deleteCookie = (name) => {
    if (typeof name !== 'string') {
        console.error('Cookie nomi string bulishi kerak');
        return;
    }
    Cookies.remove(name);
};

export const setCookie = (name, value, expiresIn = 1) => {
    if (typeof name !== 'string' || typeof value !== 'string') {
        console.error('Cookie nomi va valuesi string bulishi kerak');
        return;
    }
    if (typeof expiresIn !== 'number' && !(expiresIn instanceof Date)) {
        console.error('Utib ketish vaqti raqam yoki Date kurinishida kelishi kerak');
        return;
    }
    Cookies.set(name, value, {
        expires: expiresIn, secure: true, sameSite: 'strict',
    });
};
