export function truncateWords(input, maxLength) {
    if (!input) {
        return '';
    }

    const words = input.split(' ');

    let result = '';
    let length = 0;

    for (const word of words) {
        if (length + word.length + (result.length > 0 ? 1 : 0) + 3 <= maxLength) {
            if (result.length > 0) {
                result += ' ';
                length += 1;
            }
            result += word;
            length += word.length;
        } else {
            break;
        }
    }

    if (length < input.length) {
        result += '...';
    }

    return result;
}
