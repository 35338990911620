import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {getNewSingle} from "../../../utils/api/getUserData";

const NewSingle = () => {
    const {singleId} = useParams();
    const [newsSingle, setNewsSingle] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchNewsSingle = async () => {
            try {
                const data = await getNewSingle(singleId);
                setNewsSingle(data);
            } catch (error) {
                console.error('Get news error: ', error);
            } finally {
                setLoading(false);
            }
        };

        if (singleId) {
            fetchNewsSingle();
        }
    }, [singleId]);

    if (loading) {
        return <div className='container loader-wrapper h-100 d-flex'>
            <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    }

    if (!newsSingle) {
        return <div>News article not found.</div>;
    }

    return (
        <section className="py-4">
            <div className="container">
                <div className="single__content-view">
                    <h1 className="new__single-title">
                        {newsSingle.title}
                    </h1>
                    <div className="date__single-new">
                        {newsSingle.date}
                    </div>
                    <div className="single__new-img">
                        <img src={newsSingle.image} alt="img"/>
                    </div>
                    <div className="single_post">
                        <div className="blog_content" dangerouslySetInnerHTML={{__html: newsSingle.content}}/>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default NewSingle;
