import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import uzumBtn from '../../../assets/img/uzum-btn.webp';
import paymeBtn from '../../../assets/img/payme-btn.webp';
import degreeStar from '../../../assets/img/icons/degree_star.svg';
import degreeStarFull from '../../../assets/img/icons/degree_star-full.svg';
import {getMyDegree, getOrderList, getOrderSingle, getUserInfo} from "../../utils/api/getUserData";

const Home = () => {
        const [loaded, setLoaded] = useState(false);
        const {t} = useTranslation();
        const [userData, setUserData] = useState({});
        const [orderList, setOrderList] = useState([]);
        const [orderDetails, setOrderDetails] = useState({});
        const [myDegree, setMyDegree] = useState({});
        const [viewActive, setViewActive] = useState(true);

        useEffect(() => {
            getUserInfo()
                .then(data => setUserData(data))
                .catch(error => console.error('Get user error: ', error))
                .finally(() => setLoaded(true))
            getOrderList()
                .then(data => setOrderList(data.data))
                .catch(error => console.error('Order list error: ', error))
                .finally(() => setLoaded(true))
        }, [])

        useEffect(() => {
            getMyDegree().then(data => setMyDegree(data)).catch(error => console.error(error)).finally(() => setLoaded(true))
        }, []);
        useEffect(() => {
            const fetchOrderDetails = async () => {
                try {
                    if (Array.isArray(orderList)) {
                        const orderDetails = await Promise.all(orderList.map(order => getOrderSingle(order.id)));
                        const updatedOrderDetails = {};
                        orderDetails.forEach(detail => {
                            updatedOrderDetails[detail.id] = detail;
                        });
                        setOrderDetails(updatedOrderDetails);
                    }
                } catch (error) {
                    console.error('Error fetching view details:', error);
                }
            };

            fetchOrderDetails();
        }, [orderList])

        useEffect(() => {
            document.title = `Xaridor: ${userData.name}`;
        }, [userData]);
        const getImage = (progress, threshold) => {
            return progress >= threshold ? degreeStarFull : degreeStar;
        };

        return (<>
            {loaded ? (<section className="home">
                <div className="container">
                    <div className="row gap-home">
                        <div className="col col-lg-6 col-md-12">
                            <h2 className="title">
                                {t("Ma’lumotlar")}
                            </h2>

                            <div className="card">
                                <div className="user">
                                    <img src={userData && userData.avatar} alt=""/>
                                    <h5>{t("Xaridor :")} {userData && userData.name}</h5>
                                </div>
                                <table className='mt-4'>
                                    <tbody>
                                    <tr>
                                        <td className="table-title">
                                            <p className='list-title'>
                                                {t('Shaxsiy ID raqamingiz! (to\'lov uchun)')}
                                            </p>
                                        </td>
                                        <td>
                                            {userData && userData.pay_id}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table-title">
                                            <p className='list-title'>
                                                {t("To\'liq ism")}
                                            </p>
                                        </td>
                                        <td>
                                            {userData && userData.name}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table-title">
                                            <p className='list-title'>
                                                {t('Yoshi')}
                                            </p>
                                        </td>
                                        <td>
                                            {userData && (new Date().getFullYear() - new Date(userData.birth_data * 1000).getFullYear())}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table-title">
                                            <p className='list-title'>
                                                JShShIR
                                            </p>
                                        </td>
                                        <td>
                                            {userData && userData.pinfl}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table-title">
                                            <p className='list-title'>
                                                {t("Passport")}
                                            </p>
                                        </td>
                                        <td>
                                            {userData && userData.passport}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table-title">
                                            <p className='list-title'>
                                                {t("Daraja")}
                                            </p>
                                        </td>
                                        <td>
                                            <b>{userData && userData.degree_name}</b> <a className='text-info'
                                                                                         href="/">{t("bu nima ?")}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table-title">
                                            <p className='list-title'>
                                                {t("Keshbek")}
                                            </p>
                                        </td>
                                        <td>
                                            {userData && userData.bonus} so`m <a className='text-info'
                                                                                 href="/">{t("qanday ishlataman?")}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table-title">
                                            <p className='list-title'>
                                                {t('O\'tib ketgan to\'lovingiz')}
                                            </p>
                                        </td>
                                        <td>
                                            {userData && userData.saldo} so`m
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table-title">
                                            <p className='list-title'>
                                                {t('To\'liq rassrochkalaringiz yopilishigacha')}
                                            </p>
                                        </td>
                                        <td>
                                            {userData && userData.saldo_coming ? userData.saldo_coming : '0'} {t("so\'m qoldi")}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table-title">
                                            <p className='list-title'>
                                                {t('To\'lov qilish')}
                                            </p>
                                        </td>
                                        <td>
                                            <a target='_blank' rel="noreferrer"
                                               href={`https://asaxiy.uz/apelsin/${userData && userData.pay_id}`}
                                               className='btn p-0' style={{marginRight: 10}}>
                                                <img src={uzumBtn} alt=""/>
                                            </a>
                                            <a target='_blank' rel="noreferrer"
                                               href={`https://asaxiy.uz/payme/${userData && userData.pay_id}`}
                                               className='btn p-0'>
                                                <img src={paymeBtn} alt=""/>
                                            </a>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="my__degree-home mt-5">
                                <h2 className="title">Maqom</h2>
                                <div className="my__degree-card">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <img src={myDegree.degree_icon} className="my__degree-icon" alt=""/>
                                        <div className="my__degree-progress">
                                            <div className="d-flex justify-content-between align-items-center mb-2">
                                                <h5 style={{color: myDegree.degree_color}}>{myDegree.degree_name}</h5>
                                                <div className="my__degree-star">
                                                    <img src={getImage(myDegree.progress_percent, 20)}
                                                         alt="Degree Star"/>
                                                    <img src={getImage(myDegree.progress_percent, 40)}
                                                         alt="Degree Star"/>
                                                    <img src={getImage(myDegree.progress_percent, 60)}
                                                         alt="Degree Star"/>
                                                    <img src={getImage(myDegree.progress_percent, 80)}
                                                         alt="Degree Star"/>
                                                    <img src={getImage(myDegree.progress_percent, 100)}
                                                         alt="Degree Star"/>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <p className="m-0">Qoldi:</p>
                                                <p className="m-0">{100 - myDegree.progress_percent}%</p>
                                            </div>
                                            <div className="new_degree-progress">
                                                <span style={{width: `${myDegree.progress_percent}%`}}></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col col-lg-6 col-md-12">
                            <h2 className="title">
                                {t("Rassrochkalar")}
                            </h2>

                            <div className="  p-2 my-3 rounded bg-white">
                                <nav className="nav nav-pills nav-justified" style={{gap: '0.5rem'}}>
                                    <button className={`nav-link ${viewActive ? 'active btn btn-warning custom' : ''}`}
                                            onClick={() => setViewActive(true)} type='button'>
                                        {t("Faol")}
                                    </button>
                                    <button className={`nav-link ${viewActive ? '' : 'active btn btn-success custom'}`}
                                            onClick={() => setViewActive(false)} type='button'>
                                        {t("Tugagan")}
                                    </button>
                                </nav>
                            </div>

                            <div className="card-group tab-content">
                                {viewActive ? (<div className="card-group">
                                    {orderList.length > 0 ? (orderList.slice(0, 2).map(order => {
                                        if (order.status_badge === 'warning') {
                                            return (<div key={order.id} className="card">
                                                <ul>
                                                    <li>
                                                        <p>{t("Holat")}</p>
                                                        <p className={`btn btn-${order.status_badge} custom`}>{t(order.status_name)}</p>
                                                    </li>
                                                    <li>
                                                        <p>{t("Miqdor")}</p>
                                                        <p>{order.amount}</p>
                                                    </li>
                                                    <li>
                                                        <p>{t("Qarz miqdori")}</p>
                                                        <p>{order.saldo}</p>
                                                    </li>
                                                    <li>
                                                        <p>{t("To`lov sanasi")}</p>
                                                        <p>{order.created_at}</p>
                                                    </li>
                                                    <li>
                                                        <p>{t("Rassrochka")}</p>
                                                        <p>{orderDetails && orderDetails[order.id] && orderDetails[order.id].items ? orderDetails[order.id].items.length : 'Nomalum?'} {t("oy")}</p>
                                                    </li>
                                                    <li>
                                                        <p>{t("Kompaniya IDsi")}</p>
                                                        <p>{order.company_name}</p>
                                                    </li>
                                                    <li>
                                                        <p>{t("To\'lov tugashiga qoldi")}</p>
                                                        <p>{order.saldo_coming}</p>
                                                    </li>
                                                    <li>
                                                        <Link to={`/akt/${order.id}`}
                                                              className='btn btn-primary custom'>
                                                            {t("Aktni ko'rish")}
                                                        </Link>
                                                        <Link to={`/contracts/${order.id}`}
                                                              className='btn btn-success custom'>
                                                            {t("To\'lov qilish")}
                                                        </Link>
                                                    </li>
                                                </ul>

                                            </div>)
                                        }
                                    })) : (<div className="card">
                                        <h1 className="no-order rounded">
                                            {t("Malum Rassrochkalar yo'q")}
                                        </h1>
                                    </div>)}
                                </div>) : (<div className="card-group">
                                    {orderList.length > 0 ? (orderList.map(order => {
                                        if (order.status_badge === 'success') {
                                            return (<div key={order.id} className="card">
                                                <ul>
                                                    <li>
                                                        <p>{t("Holat")}</p>
                                                        <p className={`btn btn-${order.status_badge} custom`}>{t(order.status_name)}</p>
                                                    </li>
                                                    <li>
                                                        <p>{t("Miqdor")}</p>
                                                        <p>{order.amount}</p>
                                                    </li>
                                                    <li>
                                                        <p>{t("Qarz miqdori")}</p>
                                                        <p>{order.saldo}</p>
                                                    </li>
                                                    <li>
                                                        <p>{t("To`lov sanasi")}</p>
                                                        <p>{order.created_at}</p>
                                                    </li>
                                                    <li>
                                                        <p>{t("Rassrochka")}</p>
                                                        <p>{orderDetails && orderDetails[order.id] && orderDetails[order.id].items ? orderDetails[order.id].items.length : 'Nomalum?'} {t("oy")}</p>
                                                    </li>
                                                    <li>
                                                        <p>{t("Kompaniya IDsi")}</p>
                                                        <p>{order.company_name}</p>
                                                    </li>
                                                    <li>
                                                        <p>{t("To\'lov tugashiga qoldi")}</p>
                                                        <p>{order.saldo_coming}</p>
                                                    </li>
                                                    <li>
                                                        <Link to={`/akt/${order.id}`}
                                                              className='btn btn-primary custom'>
                                                            {t("Aktni ko\'rish")}
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>)
                                        }
                                    })) : (<div className="card">
                                        <h1 className="no-order rounded">
                                            Malum Rassrochkalar yo'q
                                        </h1>
                                    </div>)}
                                </div>)}

                                {viewActive && orderList.length > 2 ? (
                                    <div className="w-100 d-flex justify-content-end">
                                        <Link to='/contracts' className="btn btn-primary custom">
                                            {t("Ko\'proq")}
                                        </Link>
                                    </div>) : ''}

                            </div>
                        </div>
                    </div>
                </div>
            </section>) : (<div className='container loader-wrapper h-100 d-flex'>
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>)}
        </>)

    }
;

export default Home;
