import {Link, NavLink} from "react-router-dom";
import logo from '../../../assets/img/logo.svg'
import {FaRegUser} from "react-icons/fa";
import {initReactI18next, useTranslation} from "react-i18next";
import i18n from "i18next";
import {FaBars, FaFacebook, FaSquareInstagram, FaTelegram} from "react-icons/fa6";
import {useEffect, useState} from "react";
import {getUserInfo} from "../../utils/api/getUserData";
import {deleteCookie} from '../../utils/api/cookie';
import uzImage from "../../../assets/img/language/uz.svg"
import enImage from "../../../assets/img/language/en.svg"
import ruImage from "../../../assets/img/language/ru.svg"
import i18next from "i18next";
import uz from "../../utils/lang/uz";
import en from "../../utils/lang/en";
import ru from "../../utils/lang/ru";


export const defLang = 'uz';
const fallbackLang = 'en';

i18next.use(initReactI18next).init({
    resources: {
        uz: { translation: uz },
        ru: { translation: en },
        en: { translation: ru },
    },
    lng: defLang,
    fallbackLng: fallbackLang,
});


const Header = () => {

    const [userData, setUserData] = useState()

    const changeLang = (value) => {
        i18n.changeLanguage(value)
    }
    const {t} = useTranslation();

    const changeLanguageHandler = (e) => {
        changeLang(e.target.value);
    }

    const handleLogout = () => {
        deleteCookie('access_token')
        deleteCookie('refresh_token')
        window.location.reload()
    }

    useEffect(() => {
        getUserInfo()
            .then(data => setUserData(data))
            .catch(error => console.error('Get user error: ', error))
    }, []);

    // Routing List
    const routingLinks = [
        {
            title: t('Asosiy'),
            link: '/'
        },
        // {
        //     title: t('Darajalar'),
        //     link: '/degrees'
        // },
        {
            title: t('Yangiliklar'),
            link: '/news'
        },
        {
            title: t('Shartnomalar'),
            link: '/contracts'
        },
        {
            title: t('Monitoring'),
            link: '/monitoring'
        },
        {
            title: t('Kartalar'),
            link: '/cards'
        },
        // {
        //     title: "faq",
        //     link: 'faq'
        // },
        // {
        //     title: t('Ma\'lumotnomalar'),
        //     link: '/data'
        // },
    ]





    // const changeLanguage = (langName, langImage) => {
    //     setSelectedLanguage(langName);
    //     setSelectedImage(langImage);
    // };
    const [selectedLanguage, setSelectedLanguage] = useState(i18next.language);
    const [selectedImage, setSelectedImage] = useState(getLanguageImage(i18next.language));
    function getLanguageImage(lang) {
        switch (lang) {
            case 'en':
                return enImage;
            case 'ru':
                return ruImage;
            case 'uz':
                return uzImage;
            default:
                return uzImage; // Default to Uzbek if language is not found
        }
    }
    const changeLanguage = (lang) => {
        i18next.changeLanguage(lang); // Change language using i18next
        setSelectedLanguage(lang);
        setSelectedImage(getLanguageImage(lang));
    };



    return (
        <header className='bg-white'>
            <nav className="nav container">
                <a className="btn btn-success custom btn-for-mobile d-flex" data-bs-toggle="offcanvas"
                   href="#headerMenu"
                   role="button"
                   aria-controls="headerMenu">
                    <FaBars/>
                </a>
                <Link to='/' className="nav__logo">
                    <img src={logo} alt=""/>
                </Link>
                <ul className="nav__menu">
                    {
                        routingLinks.map((item, index) => (
                            <li className='nav__menu-item' key={index}>
                                <NavLink to={item.link} className="nav__menu-link">
                                    {item.title}
                                </NavLink>
                            </li>
                        ))
                    }
                    <ul className="nav__extra">
                        <div className="dropdown">
                            <button
                                className="btn btn-secondary dropdown-toggle d-flex align-items-center main-dropdown"
                                type="button" id="languageDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                <img src={selectedImage} alt="Language" className="me-2" style={{maxWidth: '24px'}}/>
                                {t(selectedLanguage)}
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="languageDropdown">
                                <li>
                                    <button className="dropdown-item d-flex align-items-center" type="button"
                                            onClick={() => changeLanguage('en')}>
                                        <img src={enImage} alt="English" className="me-2" style={{maxWidth: '24px'}}/>
                                        {t('En')}
                                    </button>
                                </li>
                                <li>
                                    <button className="dropdown-item d-flex align-items-center" type="button"
                                            onClick={() => changeLanguage('ru')}>
                                        <img src={ruImage} alt="Русский" className="me-2" style={{maxWidth: '24px'}}/>
                                        {t('Ru')}
                                    </button>
                                </li>
                                <li>
                                    <button className="dropdown-item d-flex align-items-center" type="button"
                                            onClick={() => changeLanguage('uz')}>
                                        <img src={uzImage} alt="O'zbek" className="me-2" style={{maxWidth: '24px'}}/>
                                        {t('Uz')}
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <li className="nav__extra-item dropdown">
                            <button className="btn btn-primary dropdown-toggle px-4 py-2 nav__extra-btn" type="button"
                                    data-bs-toggle="dropdown" aria-expanded="false">
                                <FaRegUser/>
                                {t("Profil")}
                            </button>
                            <ul className="dropdown-menu p-2">
                                <div className='menu p-2'>
                                    <div className='menu-wrapper w-100'>
                                        <span className="title">Ism</span>
                                        <span>{userData && userData.name}</span>
                                    </div>
                                    <div className='menu-wrapper w-100'>
                                        <span className='title'>ID raqamingiz!</span>
                                        <span>{userData && userData.pay_id}</span>
                                    </div>
                                    <div className='menu-wrapper w-100'>
                                        <span className='title'>Daraja</span>
                                        <span>{userData && userData.degree_name}</span>
                                    </div>
                                    <div className='menu-wrapper w-100'>
                                        <span className='title'>JShShR</span>
                                        <span>{userData && userData.pinfl}</span>
                                    </div>
                                    <div className='menu-wrapper w-100'>
                                        <span className='title'>Passport</span>
                                        <span>{userData && userData.passport}</span>
                                    </div>
                                    <div className='menu-wrapper w-100'>
                                        <span className='title'>Yoshi</span>
                                        <span>{userData && (new Date().getFullYear() - new Date(userData.birth_data * 1000).getFullYear())}</span>
                                    </div>
                                </div>
                                <button className='w-100 btn btn-danger custom' onClick={() => handleLogout()}>Chiqish
                                </button>
                            </ul>
                        </li>
                    </ul>
                </ul>


                <div className="offcanvas offcanvas-start" tabIndex="-1" id="headerMenu"
                     aria-labelledby="headerMenuLabel">
                    <div className="offcanvas-header">
                        <img alt='logo' src={logo} className="offcanvas-title" id="headerMenuLabel"/>
                        <button type="button" className="btn-close" data-bs-dismiss="offcanvas"
                                aria-label="Close"></button>
                    </div>
                    <div className="offcanvas-body">
                        <div className="top">
                            <ul className='offcanvas__menu'>
                                {
                                    routingLinks.map((item, index) => (
                                        <li className='offcanvas__menu-item w-100' data-bs-dismiss="offcanvas"
                                            aria-label="Close" key={index}>
                                            <NavLink to={item.link} className="offcanvas__menu-link w-100">
                                                {item.title}
                                            </NavLink>
                                        </li>
                                    ))
                                }
                            </ul>
                            <ul className="offcanvas__social">
                                <li className="offcanvas__social-item">
                                    <li className="offcanvas__social-icon">
                                        <FaFacebook/>
                                    </li>
                                    <li className="offcanvas__social-icon">
                                        <FaTelegram/>
                                    </li>
                                    <li className="offcanvas__social-icon">
                                        <FaSquareInstagram/>
                                    </li>
                                </li>
                            </ul>
                        </div>
                        <div className="bottom">
                            <ul className="offcanvas__extra">
                                <li className="offcanvas__extra-item">
                                    <a href="#" className="offcanvas__extra-link btn btn-primary rounded full">
                                        <FaRegUser/> Kirish
                                    </a>
                                </li>
                                <li className="offcanvas__extra-item">
                                    <select onChange={changeLanguageHandler}
                                            className='form-select border-0 bg-success custom'>
                                        <option value="uz">Uzbek</option>
                                        <option value="en">English</option>
                                        <option value="ru">Russian</option>
                                    </select>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    )
}

export default Header