import {useEffect, useState} from "react";
import {getOrderList, getOrderStatus} from "../../utils/api/getUserData";
import {Link} from "react-router-dom";
import {truncateWords} from "../../utils/truncateWords";
import {useTranslation} from "react-i18next";

const Contracts = () => {
    const [orderList, setOrderList] = useState([]);
    const [orderStatus, setOrderStatus] = useState({});
    const [selectedStatus, setSelectedStatus] = useState('');
    const [loaded, setLoaded] = useState(false);
    const {t} = useTranslation()

    useEffect(() => {
        getOrderList()
            .then(data => setOrderList(data.data))
            .catch(error => console.error('Order list error:', error))
            .finally(() => setLoaded(true))
        getOrderStatus()
            .then(data => setOrderStatus(data))
            .catch(error => console.error('Order status fetch error:', error))
            .finally(() => setLoaded(true))
    }, []);

    const handleSelect = (e) => {
        setSelectedStatus(e.target.value);
    };

    const filteredOrderList = selectedStatus
        ? orderList.filter(order => order.status_name === orderStatus[selectedStatus])
        : orderList;

    return (
        <>
            {loaded ? (
                <section className='contract container-fluid py-4'>
                    <div className="container">
                        <div className="contract__title mb-4 d-flex justify-content-between align-items-center">
                            <span>{t("Shartnomalar")}</span>
                            <div className="input-group" style={{width: 'auto'}}>
                                <select
                                    className="form-select border-0"
                                    aria-label="Shartnomalar holati"
                                    onChange={handleSelect}
                                    value={selectedStatus}
                                >
                                    <option value="">{t("Holatni tanlang")}</option>
                                    {Object.keys(orderStatus).length > 0 ? (
                                        Object.keys(orderStatus).map(key => (
                                            <option key={key} value={key}>{orderStatus[key]}</option>
                                        ))
                                    ) : (
                                        <option value="false">Nomalum</option>
                                    )}
                                </select>
                            </div>
                        </div>

                        <div className="cards-group d-flex flex-column" style={{gap: '1rem'}}>
                            {filteredOrderList.length > 0 ? (
                                filteredOrderList.map((order, index) => (
                                    <Link key={index} to={`/contracts/${order.id}`}
                                          className="card rounded border-0 p-3">
                                        <div className="card__line py-1">
                                            <p>{t("Sanasi :")}</p>
                                            <p>{order.created_at}</p>
                                        </div>
                                        <div className="card__line py-1">
                                            <p>{t("To’lov ID :")}</p>
                                            <p>{order.pay_id}</p>
                                        </div>
                                        <div className="card__line py-1">
                                            <p>{t("Umumiy :")}</p>
                                            <p>{order.amount} so’m</p>
                                        </div>
                                        <div className="card__line py-1">
                                            <p>{t("To’langan :")}</p>
                                            <p>{order.paid_amount} so’m</p>
                                        </div>
                                        <div className="card__line py-1">
                                            <p>{t("Keshbek")}</p>
                                            <p>{order.cashback} so’m</p>
                                        </div>
                                        <div className="card__line py-1">
                                            <p>{t("To’lanmagan :")}</p>
                                            <p>{order.saldo} so’m</p>
                                        </div>
                                        <div className="card__line py-1">
                                            <p>{t("Mahsulot :")}</p>
                                            <p>{truncateWords(order.description, 30)}</p>
                                        </div>
                                        <div className="card__line py-1">
                                            <p>{t("Holati :")}</p>
                                            <p className={`btn btn-${order.status_badge} custom`}>{order.status_name}</p>
                                        </div>
                                    </Link>
                                ))
                            ) : (
                                <div className="card rounded border-0 p-3">
                                    <div className="card__line py-1">
                                        <p>Ma'lum rassrochkalar:</p>
                                        <p className='btn btn-danger custom'>Topilmadi</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </section>
            ) : (
                <div className='container loader-wrapper h-100 d-flex'>
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            )
            }
        </>
    );
};

export default Contracts;
