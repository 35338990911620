import Header from "./header/Header";
import Footer from "./footer/Footer";
import {Outlet, useLocation} from "react-router-dom";
import Auth from "../pages/auth/Auth";
import {CardModal, PayModal} from "../utils/ui/modal";
import {ShowProvider} from "../utils/hooks/ShowContext";
import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import translationEn from "../utils/lang/en";
import translationRu from "../utils/lang/ru";
import translationUz from "../utils/lang/uz";
import {useEffect} from "react";

i18n.use(initReactI18next).init({
    resources: {
        en: {translation: translationEn},
        uz: {translation: translationUz},
        ru: {translation: translationRu}
    },
    lng: "uz",
    fallbackLng: "uz"
})


const Layout = () => {

    const {pathname} = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <>
            <ShowProvider>
                <Auth>
                    {/* Header start */}
                    <Header/>
                    {/* Header end */}

                    {/* Main Code start */}
                    <main>
                        {/* Modals start */}
                        <CardModal/>
                        <PayModal/>
                        {/* Modals end */}
                        <Outlet/>
                    </main>
                    {/* Main Code end */}

                    {/* Footer start */}
                    <Footer/>
                    {/* Footer end */}
                </Auth>
            </ShowProvider>
        </>
    )
}

export default Layout