import logo from "../../../../assets/img/logo.svg";
import {useEffect, useRef, useState} from "react";
import {getUserInfo} from "../../../utils/api/getUserData";
import axios from "../../../utils/api/axios";
import {getCookie} from "../../../utils/api/cookie";

const MyId = () => {
    const [userInfo, setUserInfo] = useState(null);
    const [isPassportAvailable, setIsPassportAvailable] = useState(false);
    const [passportSeries, setPassportSeries] = useState('');
    const [passportNumber, setPassportNumber] = useState('');
    const [dataImage, setDataImage] = useState('');
    const [birthDate, setBirthDate] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const passportSeriesRef = useRef(null);
    const passportNumberRef = useRef(null);
    const birthDateRef = useRef(null);

    const handlePassportSeriesChange = (e) => {
        const value = e.target.value.toUpperCase().slice(0, 2);
        setPassportSeries(value);
        if (value.length === 2) {
            passportNumberRef.current.focus();
        }
    };

    const handlePassportNumberChange = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, '').slice(0, 7);
        setPassportNumber(value);
        if (value.length === 7) {
            birthDateRef.current.focus();
        }
    };

    useEffect(() => {
        getUserInfo().then(data => {
            if (data && data.pinfl) {
                setUserInfo(data);
                setIsPassportAvailable(true);
            } else {
                setIsPassportAvailable(false);
            }
        }).catch(error => console.error('Foydalanuvchi malumotlarini topishda hatolik: ', error));
    }, []);

    useEffect(() => {
        const handleMessage = (e) => {
            const iframe = document.getElementById('myid_iframe');
            if (e.data.cmd === -1 && e.data.state_message === 'loaded') {
                iframe.contentWindow.postMessage({cmd: 'open', config: {locale: 'uz'}}, '*');
            }
            if (e.data.cmd === 2) {
                setDataImage(e.data.photo_from_camera);
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    const formatDateInput = (value) => {
        let input = value.replace(/\D/g, '');
        let formattedInput = '';
        if (input.length > 0) {
            formattedInput = input.substring(0, 2);
        }
        if (input.length > 2) {
            formattedInput += '.' + input.substring(2, 4);
        }
        if (input.length > 4) {
            formattedInput += '.' + input.substring(4, 8);
        }
        return formattedInput;
    };

    const validateDate = (numericValue) => {
        const day = parseInt(numericValue.substring(0, 2), 10);
        const month = parseInt(numericValue.substring(2, 4), 10);
        const year = parseInt(numericValue.substring(4, 8), 10);
        const currentYear = new Date().getFullYear();

        if (day < 1 || day > 31) {
            return "Noto'gri kun. Iltimos, qayta uruning, shu raqamlar o'rtasida bulishi kerak 01 va 31.";
        } else if (month < 1 || month > 12) {
            return "Noto'gri oy. Iltimos, qayta uruning, shu raqamlar o'rtasida bulishi kerak 01 va 12.";
        } else if (year < 1 || year > currentYear) {
            return `Noto'gri yil. Iltimos, qayta uruning, ${currentYear}dan utib ketmasligi kerak.`;
        } else {
            return "";
        }
    };

    const handleBirthDateChange = (event) => {
        const {value} = event.target;
        const formattedValue = formatDateInput(value);
        setBirthDate(formattedValue);

        const numericValue = formattedValue.replace(/\D/g, '');
        const error = numericValue.length === 8 ? validateDate(numericValue) : '';
        setErrorMessage(error);
    };


    const handlePostData = async () => {
        try {
            const access = getCookie('access_token')

            const res = await axios.post('/profile/web-my-id-verify', {
                headers: {
                    'Authorization': `Bearer ${access}`
                },
                passport_seria: userInfo ? userInfo.passport.slice(0, 2) : passportSeries,
                passport_number: userInfo ? userInfo.passport.slice(2) : passportNumber,
                birth_date: '15.10.2005',
                photo: dataImage,
                pnfl: userInfo ? userInfo.pinfl : '',
            });
            console.log(res);
        } catch (error) {
            console.error('Malumot jo\'natishda hatolik: ', error);
        }
    };

    return (<div className='auth-page'>
        {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
        <img src={logo} className='logo mb-4' alt=''/>
        <div className='bg-white shadow-sm card'>
            {isPassportAvailable ? (<>
                <h1 className='mb-4'>Shaxsingizni tastiqlang</h1>
                <div className='myid_verification mb-4'>
                    <iframe
                        style={{width: 600, height: 600, borderRadius: 16}}
                        id="myid_iframe"
                        src="https://docs.myid.uz/iframe"
                        allow="camera;fullscreen"
                        allowFullScreen
                    ></iframe>
                </div>
                <button className="btn btn-primary" onClick={handlePostData}>Tastiqlash</button>
            </>) : (<>
                <h1 className='mb-4'>ID karta va Passport malumotlarini kiriting.</h1>
                <div className="input-group mb-3">
                    <input
                        type="text"
                        className="form-control passport-first"
                        placeholder="AA"
                        maxLength={2}
                        value={passportSeries}
                        onChange={handlePassportSeriesChange}
                        ref={passportSeriesRef}
                    />
                    <input
                        type="text"
                        className="form-control passport-second"
                        placeholder="7777777"
                        maxLength={7}
                        value={passportNumber}
                        onChange={handlePassportNumberChange}
                        ref={passportNumberRef}
                    />
                </div>
                <div className="input-group mb-4">
                    <input
                        type="text"
                        className="form-control"
                        value={birthDate}
                        onChange={handleBirthDateChange}
                        placeholder="ДД.ММ.ГГГГ"
                        ref={birthDateRef}
                    />
                </div>
                <button className="btn btn-primary" onClick={handlePostData}>Keyingi bosqich</button>
            </>)}
        </div>
    </div>);
};

export default MyId;
