import React from 'react';
import faqOfferta from "../../../assets/img/icons/paper.offerta.svg"

const Faq = () => {
    return (
        <div className='container faq-container py-3'>
            <div className='faq-head py-4'>
                <h2>Ko'p so'raladigan savollar</h2>
            </div>      
            <div className="accordion py-4" id="accordionExample">
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                            Accordion Item #1
                        </button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            {/* Nested Accordion */}
                            <div className="accordion nested-accordion" id="nestedAccordionExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#nestedCollapseOne" aria-expanded="true" aria-controls="nestedCollapseOne">
                                            Xizmatdan qanday foydalanaman ?
                                        </button>
                                    </h2>
                                    <div id="nestedCollapseOne" className="accordion-collapse collapse show" data-bs-parent="#nestedAccordionExample">
                                        <div className="accordion-body">
                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolores sequi quod porro quae suscipit sit eligendi, nulla placeat quaerat error.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#nestedCollapseTwo" aria-expanded="false" aria-controls="nestedCollapseTwo">
                                            Ro'yxatdan qanday o'tiladi ?
                                        </button>
                                    </h2>
                                    <div id="nestedCollapseTwo" className="accordion-collapse collapse" data-bs-parent="#nestedAccordionExample">
                                        <div className="accordion-body">
                                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maiores, sapiente ipsa ipsam nesciunt cupiditate debitis quisquam sint! Velit, sequi quis? Corrupti vitae ullam illum voluptate velit expedita atque! Voluptatum fugiat tenetur numquam labore, repellat harum?
                                        </div>
                                    </div>
                                </div>
                                {/* Additional Nested Accordions */}
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#nestedCollapseThree" aria-expanded="false" aria-controls="nestedCollapseThree">
                                            Agar rad javobi kelsa nima qilish kerak ?
                                        </button>    
                                    </h2>
                                    <div id="nestedCollapseThree" className="accordion-collapse collapse" data-bs-parent="#nestedAccordionExample">
                                        <div className="accordion-body">
                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos aspernatur earum vitae unde omnis, cumque fugit enim dolorum corrupti itaque commodi hic voluptatum officia expedita culpa delectus minus ad, eaque nam. Labore ipsum laudantium pariatur.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#nestedCollapseFour" aria-expanded="false" aria-controls="nestedCollapseFour">
                                            Agar oldin rad kelgan kelgan bo'lsa unda qanday ro'yxatdan o'tish mumkin
                                        </button>
                                    </h2>
                                    <div id="nestedCollapseFour" className="accordion-collapse collapse" data-bs-parent="#nestedAccordionExample">
                                        <div className="accordion-body">
                                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Animi, maxime commodi, deserunt, in cum optio iusto excepturi aspernatur quasi placeat magnam? Unde alias illum molestiae?
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#nestedCollapseFive" aria-expanded="false" aria-controls="nestedCollapseFive">
                                            Ro'yxatdan o'tish davomida qanday karta taqdim qilish kerak
                                        </button>
                                    </h2>
                                    <div id="nestedCollapseFive" className="accordion-collapse collapse" data-bs-parent="#nestedAccordionExample">
                                        <div className="accordion-body">
                                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Numquam, ex nemo perspiciatis voluptatum aperiam quos suscipit quaerat delectus laudantium fugit debitis, ad commodi placeat doloremque harum nihil, dolorem animi eius. At quidem adipisci quos provident!
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#nestedCollapseSix" aria-expanded="false" aria-controls="nestedCollapseSix">
                                            Tizimga ikki marotaba ro'yxatdan o'tsa bo'ladimi
                                        </button>
                                    </h2>
                                    <div id="nestedCollapseSix" className="accordion-collapse collapse" data-bs-parent="#nestedAccordionExample">
                                        <div className="accordion-body">
                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum id fuga nemo tempore excepturi sed voluptatem iusto? Blanditiis, earum obcaecati dignissimos ipsum, nam molestiae tempora omnis animi vitae fuga saepe. Dignissimos soluta ratione, perferendis velit enim nostrum laboriosam necessitatibus voluptatum.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#nestedCollapseSeven" aria-expanded="false" aria-controls="nestedCollapseSeven">
                                            Ro'yxatdan o'tish necha pul turadi
                                        </button>
                                    </h2>
                                    <div id="nestedCollapseSeven" className="accordion-collapse collapse" data-bs-parent="#nestedAccordionExample">
                                        <div className="accordion-body">
                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere atque commodi dicta ut dolore soluta similique? Itaque repellendus sit quia deleniti labore unde, odio tenetur maxime saepe sunt voluptatibus suscipit corporis reprehenderit, officiis placeat ab deserunt, fugiat nam? Nulla reprehenderit sit quos ex tempora quidem!
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End of Nested Accordion */}
                        </div>
                    </div>
                </div>           
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            Bonuslar
                        </button>
                    </h2>
                    <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions.
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            To'lov xizmatidan ro'yxatdan o'tish
                        </button>
                    </h2>
                    <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions.
                        </div>
                    </div>
                </div>

                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                            Mavjud limit
                        </button>
                    </h2>
                    <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions.
                        </div>
                    </div>
                </div>

                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                            Profil va sozlamalar
                        </button>
                    </h2>
                    <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions.
                        </div>
                    </div>
                </div>
            </div>

            <a href="https://asaxiy.uz/uz/page/rules">
                <div className='box-offerta p-3 d-flex'>
                    <div className='offerImg '><img src={faqOfferta} alt="" /></div>
                    <div className='offerRight ml-3'>
                        <h6>Ommaviy Offerta</h6>
                        <span className='offerta-mb'>2.3 mb</span>
                    </div>
                </div>
            </a>

        </div>
    );
}

export default Faq;
