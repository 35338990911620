import React, { useEffect, useState, useCallback } from 'react';
import GobackImg from '../../../assets/img/icons/back__black.svg';
import { getAllNews } from '../../utils/api/getUserData';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const News = () => {
    const [newslist, setNewsList] = useState([]);
    const [page, setPage] = useState(1);
    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    const fetchNews = useCallback((page) => {
        setLoading(true);
        getAllNews(page)
            .then(data => {
                setNewsList(prevNewsList => [...prevNewsList, ...data.data]);
            })
            .catch(error => console.error('Get news error: ', error))
            .finally(() => {
                setLoaded(true);
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        fetchNews(page);
    }, [fetchNews, page]);

    const handleScroll = useCallback(() => {
        if (window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight && !loading) {
            setPage(prevPage => prevPage + 1);
        }
    }, [loading]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [handleScroll]);

    const goBack = () => {
        window.history.back();
    };

    return (
        <>
            {loaded ? (
                <section className="py-4">
                    <div className="container">
                        <div className="news__top">
                            <button onClick={goBack}>
                                <img src={GobackImg} alt="go back" width="24" height="24" />
                            </button>
                            <h3 className="news__title">{t("Yangiliklar")}</h3>
                        </div>
                        <div className="news__main row">
                            {newslist.map((newsItem, index) => (
                                <Link key={index} to={`/news/${newsItem.id}`} className=" col-12 col-md-6 col-xl-3 col-lg-4">
                                    <div className="news__card"
                                         style={{ backgroundImage: `url(${newsItem.image})` }}>
                                        <div className="news__card-before">
                                            <span>{newsItem.date}</span>
                                            <h6>{newsItem.title}</h6>
                                            <p className="news__card-description">{newsItem.description}</p>
                                        </div>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </div>
                </section>
            ) : (
                <div className='container loader-wrapper h-100 d-flex'>
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            )}
        </>
    );
};

export default News;
