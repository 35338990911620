const translationRu = {
    "Asosiy" : 'Asosiy',
    "Yangiliklar" : 'Yangiliklar',
    'Darajalar':'Darajalar',
    'Shartnomalar':'Shartnomalar',
    'Monitoring':'Monitoring',
    'Kartalar':'Kartalar',
    'Ma\'lumotnomalar':'Ma\'lumotnomalar',
}

export default translationRu;