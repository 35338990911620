import {useEffect, useState} from 'react'
import axios from '../../utils/api/axios'
import logo from '../../../assets/img/logo.svg'
import {getCookie, setCookie} from '../../utils/api/cookie'
import {getUserInfo} from "../../utils/api/getUserData";

const Auth = ({children}) => {
    const [isLogin, setIsLogin] = useState(0)
    const [num, setNum] = useState('+998')
    const [otp, setOtp] = useState('')
    const [otpSent, setOtpSent] = useState(false)
    const [data, setData] = useState({})
    const [myIdStatus, setMyIdStatus] = useState(true)

    // Expire check
    const refreshTokenIfNeeded = async () => {
        // Retrieve access token and expiration time from the cookie
        const accessToken = getCookie('access_token')
        // Check if access token is expired or doesn't exist
        if (accessToken) {
            try {
                getUserInfo().then(data => {
                    if (data.my_id_verified) {
                        setIsLogin(true)
                    } else {
                        setIsLogin(false)
                    }
                })
                setIsLogin(false)
            } catch (error) {
                console.error(error)
            }
        } else {
            // Retrieve refresh token from the cookie
            const refreshToken = getCookie('refresh_token')

            // Send request to get new access token using the refresh token
            if (refreshToken) {
                try {
                    const res = await axios.post('/auth/refresh-token', {
                        refresh_token: `${refreshToken}`,
                    })
                    setCookie('access_token', res.data.data.access_token, res.data.data.expires_in / (24 * 60 * 60))

                    setCookie('refresh_token', res.data.data.refresh_token, 30)
                    window.location.reload()
                } catch (error) {
                    console.error('Error refreshing access token:', error)
                }
            } else {
                setIsLogin(false)
                console.warn('Not authorized: ' + data)
            }
        }
    }

    // Call refreshTokenIfNeeded when the page loads
    useEffect(() => {
        refreshTokenIfNeeded()
    }, [])

    // Expire check END

    const sendOtp = async () => {
        try {
            const res = await axios.post('/auth/send-otp', {
                phone_number: num,
            })

            if (res.status) {
                setOtpSent(true)
            } else {
                setOtpSent(false)
            }

        } catch (error) {
            console.error(error)
        }
    }

    const handleKeyPressNum = event => {
        if (event.key === 'Enter') {
            sendOtp()
        }
    }

    const handleKeyPressOtp = event => {
        if (event.key === 'Enter') {
            verifyOtp()
        }
    }

    const verifyOtp = async () => {
        try {
            const res = await axios.post('/auth/login', {
                phone: `${num}`, password: `${otp}`,
            })
            setData({
                data: res.data,
                // message: res.data.message ? res.data.message : 'OTP kod tastiqlandi',
                className: 'text-success',
            })

            // Set access token as HttpOnly cookie with expiration time
            setCookie('access_token', res.data.data.access_token, res.data.data.expires_in / (24 * 60 * 60))

            // Set refresh token as HttpOnly cookie without expiration time
            setCookie('refresh_token', res.data.data.refresh_token, 30)

            if (res.data.data.me.my_id_verified) {
                window.location.reload()
            } else if (res.data.data.me.pinfl !== '' && res.data.data.me.passport !== '') {
                setMyIdStatus(false)
            }
        } catch (error) {
            console.error(error)
        }
    }
    const testLogin = false
    return (<>
        {isLogin ? (children) : (<div className='auth-page'>
            {myIdStatus ? '' : (<div className="alert alert-danger" role="alert">
                Asaxiy Nasiya ilovasini yuklang va shaxsingizni tasdiqlang <a className="text-primary h5" href="https://asaxiy.uz/apps/asaxiynasiya">Ilova</a>
            </div>)}
            <img src={logo} className='logo mb-4' alt=''/>
            <div className='bg-white shadow-sm card'>
                <h1 className='mb-4'>ASAXIY NASIYA</h1>
                <div>
                    <label className='mb-1'>Telefon raqamingiz</label>
                    <input
                        type='tel'
                        className='form-control mb-2'
                        placeholder='+998_________'
                        maxLength='13'
                        value={num}
                        onChange={e => setNum(e.target.value)}
                        onKeyPress={handleKeyPressNum}
                    />
                </div>

                {otpSent ? (<div>
                    <label className='mb-1'>Tasdiqlash kodini kiriting</label>
                    <input
                        className='form-control'
                        type='number'
                        placeholder='00000'
                        value={otp}
                        onChange={e => setOtp(e.target.value)}
                        onKeyPress={handleKeyPressOtp}
                    />
                    <div className='form-check mt-1'>
                        <input
                            className='form-check-input'
                            type='checkbox'
                            id='remember'
                        />
                        <label
                            className='form-check-label remember'
                            htmlFor='remember'
                        >
                            Meni eslab qolish
                        </label>
                    </div>
                </div>) : ('')}

                {otpSent ? (<button onClick={verifyOtp} className='btn btn-primary mt-3'>
                    Kirish
                </button>) : (<button onClick={sendOtp} className='btn btn-primary mt-3'>
                    Tasdiqlash kodini jo'natish
                </button>)}

                {/*<p className={`message mt-1 ${data.className}`}>*/}
                {/*    {data.message === 'The user not found with this phone number' ? 'Bu raqamli foydalanuvchi topilmadi' : data.message}*/}
                {/*</p>*/}
            </div>
        </div>)}
    </>)
}

export default Auth
