import bgCard from '../../../assets/img/bgCard.png'
import bankLogo from '../../../assets/img/bankLogo.png'
import cardType from '../../../assets/img/cardType.png'
import { useContext, useEffect, useState } from 'react'
import { ShowContext } from '../hooks/ShowContext'

// Images
import uzumPay from '../../../assets/img/payment/uzum.svg'
import paymePay from '../../../assets/img/payment/payme.svg'
import { Link } from 'react-router-dom'

// MODAL FOR BIND CARDS
export const CardModal = () => {
	const [isCard, setIsCard] = useState(false)
	const [number, setNumber] = useState('')
	const [eMonth, setEMonth] = useState('')
	const [yMonth, setYMonth] = useState('')
	const { isShow, setIsShow } = useContext(ShowContext)

	const handleCardNumberChange = e => {
		let value = e.target.value.replace(/\D/g, '') 
		value = value.substring(0, 16) 
		const formattedValue = value.replace(/(.{4})/g, '$1 ').trim() 
		setNumber(formattedValue)
	}

	const months = Array.from({ length: 12 }, (_, i) =>
		(i + 1).toString().padStart(2, '0')
	)
	const years = Array.from({ length: 6 }, (_, i) => (24 + i).toString())

	const handleModalClick = () => {
		setIsShow(false)
	}

	const handleContentClick = e => {
		e.stopPropagation()
	}

	useEffect(() => {
		if (isShow) {
			document.body.style.overflow = 'hidden'
		} else {
			document.body.style.overflow = 'visible'
		}
	}, [isShow])

	return (
		<>
			<div
				className={`modal-card ${isShow ? 'show' : 'hide'}`}
				onClick={handleModalClick}
			>
				<div
					className='content col-11 col-md-8 col-lg-6 col-xl-4 bg-white p-3 rounded'
					onClick={handleContentClick}
				>
					<div
						className='card-preview rounded p-3 mb-3'
						style={{ backgroundImage: `url(${bgCard})` }}
					>
						<div className='card-top'>
							<img className='bank-logo' src={bankLogo} alt='' />
							<img className='card-type' src={cardType} alt='' />
						</div>
						<div className='card-bottom'>
							<div className='numer'>
								<span className='text-gray'>Ism Familiya</span>
								<p>{number !== '' ? number : '#### #### #### ####'}</p>
							</div>
							<div className='expire'>
								<p className='text-gray'>
									{eMonth !== '' && yMonth !== ''
										? `${eMonth}/${yMonth}`
										: 'MM/YY'}
								</p>
							</div>
						</div>
					</div>

					<div className='input-group card-num mb-3'>
						<input
							type='text'
							className='form-control'
							maxLength={19} // 16 digits + 3 spaces
							placeholder='Enter your Card number'
							value={number}
							onChange={handleCardNumberChange}
						></input>
					</div>
					<div className='input-group mb-3 expire-date'>
						<select
							className='form-select'
							value={eMonth}
							onChange={e => setEMonth(e.target.value)}
						>
							<option value=''>MM</option>
							{months.map(month => (
								<option key={month} value={month}>
									{month}
								</option>
							))}
						</select>
						<select
							className='form-select'
							value={yMonth}
							onChange={e => setYMonth(e.target.value)}
						>
							<option value=''>YY</option>
							{years.map(year => (
								<option key={year} value={year}>
									{year}
								</option>
							))}
						</select>
					</div>
					{isCard ? (
						<>
							<div className='input-group btn-add'>
								<input
									type='text'
									placeholder='Enter confirmation code'
									className='form-control mb-3'
								/>
							</div>
							<button
								className='btn btn-primary'
								onClick={() => setIsCard(!isCard)}
							>
								Save
							</button>
						</>
					) : (
						<button
							className='btn btn-primary'
							onClick={() => setIsCard(!isCard)}
						>
							Add Card
						</button>
					)}
				</div>
			</div>
		</>
	)
}

// MODAL FOR PAYMENT
export const PayModal = () => {
	const { isShowPay, setIsShowPay, isPay } = useContext(ShowContext)

	const handleModalClick = () => {
		setIsShowPay(false)
	}

	const handleContentClick = e => {
		e.stopPropagation()
	}

	useEffect(() => {
		if (isShowPay) {
			document.body.style.overflow = 'hidden'
		} else {
			document.body.style.overflow = 'visible'
		}
	}, [isShowPay])

	return (
		<>
			<div
				className={`modal-card pay-modal ${isShowPay ? 'show' : 'hide'}`}
				onClick={handleModalClick}
			>
				<div
					className='content col-11 col-md-8 col-lg-6 col-xl-4 bg-white p-3 rounded'
					onClick={handleContentClick}
				>
					<b>
						To'lov summasini kiriting, kiritgan summangiz 5000dan kam
						bo'lmasligi kerak
					</b>
					<div className='pay-btn mt-3 mb-2'>
						<Link
							target='_blank'
							to={isPay && isPay[0].pay_link ? isPay[0].pay_link : ''}
							className='btn py-5'
						>
							<img src={uzumPay} alt='' />
						</Link>
						<Link
							target='_blank'
							to={isPay && isPay[1].pay_link ? isPay[1].pay_link : ''}
							className='btn py-5'
						>
							<img src={paymePay} alt='' />
						</Link>
					</div>
					<label htmlFor='summary' className='mb-1'>
						Miqdori
					</label>
					<div className='input-group mb-3'>
						<input type='number' id='summary' className='form-control' />
					</div>
					<button className='btn btn-primary'>To'lash</button>
				</div>
			</div>
		</>
	)
}
