import React, {createContext, useState} from 'react';

export const ShowContext = createContext();

export const ShowProvider = ({children}) => {
    const [isShow, setIsShow] = useState(false);
    const [isShowPay, setIsShowPay] = useState(false);
    const [isPay, setIsPay] = useState(false);

    return (
        <ShowContext.Provider value={{isShow, setIsShow, isShowPay, setIsShowPay, isPay, setIsPay}}>
            {children}
        </ShowContext.Provider>
    );
};
