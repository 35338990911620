const translationEn = {
    'Yangiliklar' : 'News',
    'Asosiy':'Main',
    'Darajalar':'Levels',
    'Shartnomalar':'Agreements',
    'Monitoring':'Monitoring',
    'Kartalar':'Cards',
    'Ma\'lumotnomalar':'References',
    'Ma’lumotlar' : 'Data',
    "Xaridor :" : 'Client:',
    'Shaxsiy ID raqamingiz! (to\'lov uchun)' : 'Your personal ID number! (for payment)',
    'To\'liq ism' : 'Full name',
    'Yoshi' : 'Age',
    'Passport' : 'Passport',
    'Daraja' : 'Degree',
    'Keshbek' : 'Cashback',
    'O\'tib ketgan to\'lovingiz' : 'Your past due payment',
    'To\'liq rassrochkalaringiz yopilishigacha' : 'Until the close of our full installments',
    'To\'lov qilish' : 'Payment',
    'bu nima ?' : 'what is this ?',
    'qanday ishlataman?' : 'how do i use',
    'Rassrochkalar' : 'Installments',
    'Faol' : 'Active',
    'Tugagan' : 'Finished',
    'Malum Rassrochkalar yo\'q' : 'No known installments',
    'Ko\'proq' : 'More',
    'Endi bozorga borishga hojat yo\'q' : 'No need to go to the market anymore',
    'Tez yetkazib berish' : 'Fast delivery',
    'Siz uchun qulayliklar' : 'Amenities for you',
    'Bo\'lib to\'lash' : 'Installment',
    'Bizda qulay narxlar va uyga yetkazib berish mavjud' : 'We have affordable prices and home delivery',
    'Bizning xizmatimiz sizni ajablantiradi' : 'Our service will surprise you',
    'Nosozlik yuzaga kelganda tez rasmiylashtirish va qaytarish kafolati' : 'Fast clearance and return guarantee in case of failure',
    '3, 6, 9 va 12 oy davomida oldindan to’lovsiz' : '3, 6, 9 and 12 months without advance payment',
    'Biz bilan aloqa' : 'Contact us',
    'Erishgan yutuqlarimiz' : 'Our achievements',
    'Holat' : 'Status',
    'Miqdor' : 'Quantity',
    'Qarz miqdori' : 'Debt amount',
    'To`lov sanasi' : 'Date pay',
    "Rassrochka" : 'Installment',
    'Kompaniya IDsi' : 'Company ID',
    'To\'lov tugashiga qoldi' : 'Payment is pending',
    'Aktni ko\'rish' : 'View the act',
    'Holatni tanlang' : 'Select status',
    'Mening kartalarim' : 'My cards',
    'Karta qo\'shish' : 'Add card',
    'Profil' : 'Profile',
    'Sanasi :' : 'Date:',
    'To’lov ID :' : 'Pay ID',
    'Umumiy :' : 'General',
    'To’langan :' : 'Paid',
    'To’lanmagan :' : 'Not paid',
    'Mahsulot :' : 'Product:',
    'Holati :' : 'Status',
    'To\'lov': 'Платить',
    'Kechirasiz, sahifa toplimadi!' : 'Sorry, page not found!',
    'Bosh sahifaga qaytish' : 'Return to home page',
    'Shartnoma :' : 'Contracts :',
    "Vaqti :" : 'Time :',
    "To'lov usuli :" : 'Pay method :',
    'Summa :' : 'Amount :',
    'Tranzaksiya raqami :' : 'Transaction number :',
    'Ulashish' : 'Sharing',
    "Biz ijtimoiy tarmoqlarda" : 'We are on social networks',
    'Ko\'p so\'raladigan savollar' : 'FAQ',
    'Qabul qilish punktlari' : 'Pickup points',
    'Bizning brendlarimiz' : 'Our brands',
    'Ommaviy taklif (Foydalanuvchi shartnomasi)' : 'Public offer (User Agreement)',
    'Bo\'lib-bo\'lib xarid qilish uchun taklif' : 'Offer for purchases in installments',
    'Biz haqimizda' : 'About Us',
    'To\'lov turlari' : 'Payment types',
    'Kitob do\'konlari' : 'Bookstores',
    'so\'m qoldi': 'sum left',
    'oy' : 'month',




}

export default translationEn;