import React, {useEffect, useState, useCallback} from 'react';
import transitionIcon from '../../../assets/img/icons/transaction_icon.svg';
import {getTransactions, getTransactionsSingle} from '../../utils/api/getUserData';
import asaxiynasiya from '../../../assets/img/asaxiy-nasiya.jpg';
import numbersImg from '../../../assets/img/icons/Number.svg';
import nots from "../../../assets/img/not.svg";
import {GoClock} from "react-icons/go";
import {LiaFileContractSolid, LiaCreditCardSolid} from "react-icons/lia";
import {CiMoneyBill} from "react-icons/ci";
import {LuShare2} from "react-icons/lu";
import {FaUser} from "react-icons/fa";
import {useTranslation} from "react-i18next";
import telegram from '../../../assets/img/logos_telegram.png';
import whatsapp from '../../../assets/img/logos_whatsapp-icon.svg';
import facebook from '../../../assets/img/logos_facebook.svg';

const Monitoring = () => {
    const [transactions, setTransactions] = useState([]);
    const [transactionSingle, setTransactionSingle] = useState(null);
    const [id, setId] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [newItems, setNewItems] = useState([]);
    const {t} = useTranslation();

    const fetchTransactions = useCallback((page) => {
        setLoading(true);
        getTransactions(page)
            .then((data) => {
                setTransactions(prevTransactions => [...prevTransactions, ...data]);
                setNewItems(data.map(item => item.id));
            })
            .catch((error) => console.error('Get user error: ', error))
            .finally(() => {
                setLoaded(true);
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        fetchTransactions(page);
    }, [fetchTransactions, page]);

    const handleScroll = useCallback(() => {
        if (window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight && !loading) {
            setPage(prevPage => prevPage + 1);
        }
    }, [loading]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [handleScroll]);

    useEffect(() => {
        if (id !== null) {
            getTransactionsSingle(id)
                .then(data => setTransactionSingle(data))
                .catch(error => console.error(error))
                .finally(() => setLoaded(true));
        }
    }, [id]);

    const goBack = () => {
        window.history.back();
    };

    return (
        <>
            {loaded ? (
                <section className="my-4">
                    <div className="container monitoring-container">
                        <h2 className="monitoring__title">{t("Monitoring")}</h2>
                        <div className="monitoring-page">
                            {transactions.length > 0 ? (
                                transactions.map((transaction) => (
                                    <div key={transaction.id}
                                         className={`contract-card rounded d-flex justify-content-between ${newItems.includes(transaction.id) ? 'new' : ''}`}
                                         data-bs-toggle="modal"
                                         data-bs-target="#exampleModal"
                                         onClick={() => setId(transaction.id)}>
                                        <div className="d-flex">
                                            <img src={transitionIcon} alt="Transaction Icon"/>
                                            <div className="contract-card-letter">
                                                <p className="contract-card-letter-p">{transaction.type_name}</p>
                                                <p className="contract-card-letter-hours">
                                                    {transaction.created_at.split(' ')[0]}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="payment__amount"
                                             style={{color: transaction.type === 1 ? '#EF3B3B' : '#00BFAF'}}>
                                            {transaction.amount} so'm
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className="monitoring-notfound-section">
                                    <img className="monitoring-notofund-img" src={nots} alt=""/>
                                    <h1>Sizda to’lov monitoringi mavjud emas</h1>
                                    <p>
                                        Muddatli to’lovga maxsulotlarni xarid qilganingizdan so’ng to’lov monitoringini
                                        ko’rishiz mumkin
                                    </p>
                                </div>
                            )}
                        </div>
                        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel"
                             aria-hidden="true">
                            <div className="modal-dialog mx-auto modal-dialog__transiction">
                                <div className="modal-content mx-auto">
                                    <div className="modal-body p-4">
                                        {transactionSingle ? (
                                            <div>
                                                <div className="modall__img-transaction mb-4">
                                                    <img src={asaxiynasiya} alt="Contract"/>
                                                </div>
                                                <div className="modal__transaction-content">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div className="d-flex align-items-center">
                                                            <div className="modal__items-background"><FaUser/></div>
                                                            <span
                                                                className="first__text-modal">{transactionSingle.full_name}</span>
                                                        </div>
                                                        <span
                                                            className="modal__transaction-text">{transactionSingle.phone}</span>
                                                    </div>
                                                    <div
                                                        className="d-flex justify-content-between align-items-center mt-3">
                                                        <div className="d-flex align-items-center">
                                                            <div className="modal__items-background">
                                                                <LiaFileContractSolid/></div>
                                                            <span
                                                                className="first__text-modal">{t("Shartnoma :")}</span>
                                                        </div>
                                                        <span
                                                            className="modal__transaction-text">{transactionSingle.order_id}</span>
                                                    </div>
                                                    <div
                                                        className="d-flex justify-content-between align-items-center mt-3">
                                                        <div className="d-flex align-items-center">
                                                            <div className="modal__items-background"><GoClock/></div>
                                                            <span className="first__text-modal">{t("Vaqti :")}</span>
                                                        </div>
                                                        <span
                                                            className="modal__transaction-text">{transactionSingle.created_at.split(' ')[1]}</span>
                                                    </div>
                                                    <div
                                                        className="d-flex justify-content-between align-items-center mt-3">
                                                        <div className="d-flex align-items-center">
                                                            <div className="modal__items-background">
                                                                <LiaCreditCardSolid/></div>
                                                            <span
                                                                className="first__text-modal">{t("To'lov usuli :")}</span>
                                                        </div>
                                                        <span
                                                            className="modal__transaction-text">{transactionSingle.payment_name}</span>
                                                    </div>
                                                    <div
                                                        className="d-flex justify-content-between align-items-center mt-3">
                                                        <div className="d-flex align-items-center">
                                                            <div className="modal__items-background"><CiMoneyBill/>
                                                            </div>
                                                            <span className="first__text-modal">{t("Summa :")}</span>
                                                        </div>
                                                        <span
                                                            className="modal__transaction-text">{transactionSingle.amount} so'm</span>
                                                    </div>
                                                    <div
                                                        className="d-flex justify-content-between align-items-center mt-3">
                                                        <div className="d-flex align-items-center">
                                                            <div className="modal__items-background"><img
                                                                src={numbersImg}/></div>
                                                            <span
                                                                className="first__text-modal">{t("Tranzaksiya raqami :")}</span>
                                                        </div>
                                                        <span
                                                            className="modal__transaction-text">{transactionSingle.transaction_id}</span>
                                                    </div>
                                                    <li className="dropdown">
                                                        <button
                                                            className="w-100 py-2 mt-4 button__send-modal dropdown-toggle"
                                                            type="button"
                                                            data-bs-toggle="dropdown" aria-expanded="false">
                                                            <LuShare2/> {t("Ulashish")}
                                                        </button>
                                                        <ul className="dropdown-menu p-2 share-boxs">
                                                            <div className='menu p-2'>
                                                                <div className='menu-wrapper d-flex box-share'>
                                                                    <a href="https://t.me/" target="_blank"> <img
                                                                        src={telegram} alt=""/></a>
                                                                    <img src={facebook} alt=""/>
                                                                    <img src={whatsapp} alt=""/>
                                                                </div>
                                                                <div className='menu-wrapper w-100'>
                                                                    <p>Yoki havola orqali ulashing:</p>
                                                                </div>
                                                                <div className='menu-wrapper w-100 link-to'>
                                                                    <p>havola</p>
                                                                </div>
                                                            </div>
                                                        </ul>
                                                    </li>
                                                </div>
                                            </div>
                                        ) : (
                                            <div>No card selected</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            ) : (
                <div className='container loader-wrapper h-100 d-flex'>
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            )}
        </>
    );
};

export default Monitoring;
