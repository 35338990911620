import {SlArrowLeft} from "react-icons/sl";
import {Link, useParams} from "react-router-dom";
import {FaCircleCheck, FaCircleXmark} from "react-icons/fa6";
import React, {useEffect, useState} from "react";
import {getOrderSingle} from "../../../utils/api/getUserData";
import {truncateWords} from "../../../utils/truncateWords";

const Details = () => {

    const [order, setOrder] = useState(null)

    const {orderId} = useParams()
    // Function for get single order
    useEffect(() => {
        getOrderSingle(orderId).then(data => setOrder(data)).catch(error => console.error('Aniqroq bulgan malumotlar topilmadi ', error))
    }, [orderId])

    return (<section className='detailed container-fluid py-4'>
        <div className="container">
            <div className="detailed__header mb-4">
                <Link to='/contracts' className='back'>
                    <SlArrowLeft/>
                </Link>
                <h1 className='title'>Tafsilotlar</h1>
            </div>

            <div className="detailed__akt mb-4">
                <h1 className="detailed__title mb-4">AKT nomeri buyurtmalari bo’yicha</h1>
                <div className="cards-group my-2 d-flex flex-column">
                    <div className="card mb-2 rounded border-0 p-3">
                        <div className="card__title py-1">
                            <h1>{order && order.created_at ? order.created_at : ''}</h1>
                            <p className={`btn btn-${order && order.status_badge ? order.status_badge : "danger"} custom`}>{order && order.status_name ? order.status_name : "No'malum"}</p>
                        </div>
                        <div className="card__line py-1">

                            <p>Buyurtma vaqti:</p>
                            <p>{order && order.created_at ? order.created_at : ''}</p>
                        </div>
                        <div className="card__line py-1">
                            <p>Birinchi to’lov muddati:</p>
                            <p>{order && order.starts_in ? order.starts_in : ''}</p>
                        </div>
                        <div className="card__line py-1">
                            <p>Keyingi to’lov sanasi:</p>
                            <p>{order && order.next_pay_date ? order.next_pay_date : ''}</p>
                        </div>
                        <div className="card__line py-1">
                            <p>Manzil:</p>
                            <p>{order && order.full_address ? truncateWords(order.full_address, 50) : ''}</p>
                        </div>
                    </div>

                    <div className="card rounded border-0 p-3">
                        <div className="card__line py-1">
                            <p>Umumiy:</p>
                            <p>{order && order.amount ? order.amount : ''}</p>
                        </div>
                        <div className="card__line py-1">
                            <p>Maxsulot:</p>
                            <p>{order && order.description ? truncateWords(order.description, 70) : ''}</p>
                        </div>
                        <div className="card__line py-1">
                            <p>To’landi:</p>
                            <p>{order && order.paid_amount ? order.paid_amount : ''}</p>
                        </div>
                        <div className="card__line py-1">
                            <p>Keshbek:</p>
                            <p>{order && order.cashback ? order.cashback : '0'} so'm</p>
                        </div>
                        <div className="card__line py-1">
                            <p>Qolgan to’lov:</p>
                            <p>{order && order.saldo ? truncateWords(order.saldo, 50) : ''}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="detailed__graph">
                <h1 className='detailed__title mb-4'>To’lov grafigi</h1>
                <div className="card rounded border-0 p-3">
                    <table>
                        <thead>
                        <tr>
                            <th className='align-left'>To’lov sanasi:</th>
                            <th>To’langan:</th>
                            <th>Umumiy:</th>
                            <th className='align-right'>Holati:</th>
                        </tr>
                        </thead>
                        <tbody>
                        {order && order.items ? order.items.map((item, index) => (
                            <tr key={index}>
                                <td className='align-left'>{item.starts_in}</td>
                                <td>{item.paid_amount}</td>
                                <td>{item.amount}</td>
                                <td className={`align-right ${item.status_badge !== 'success' ? 'danger' : ''}`}>
                                    {
                                        item.status_badge !== 'success' ? <FaCircleXmark/> :
                                            <FaCircleCheck/>
                                    }
                                </td>
                            </tr>
                        )) : (
                            <tr>
                                <td className='align-left'>No data</td>
                                <td>No data</td>
                                <td>No data</td>
                                <td className='align-right danger'><FaCircleXmark/></td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </section>)
}

export default Details