import React from 'react';
import ErrorImg from '../../../assets/img/notofund.png'
import {useTranslation} from "react-i18next";

const Error = () => {
    const {t} = useTranslation()
    return (
        <>
            <div className="container">
                <div className="error__content">
                    <h3>404</h3>
                    <h3>{t("Kechirasiz, sahifa toplimadi!")}</h3>
                    <img className="error__img" src={ErrorImg} alt="error img"/>
                    <a href="/"><p>{t("Bosh sahifaga qaytish")}</p></a>
                </div>
            </div>
        </>
    );
};

export default Error;