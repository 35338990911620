const translationRu = {
    "Yangiliklar" : 'Новости',
    'Asosiy':'Основной',
    'Darajalar':'Уровни',
    'Shartnomalar':'Соглашения',
    'Monitoring':'Мониторинг',
    'Kartalar':'Карты',
    'Ma\'lumotnomalar':'Рекомендации',
    'Ma’lumotlar' : 'Данные',
    "Xaridor :" : 'Клиент:',
    'Shaxsiy ID raqamingiz! (to\'lov uchun)' : 'Ваш личный идентификационный номер! (для оплаты)',
    'To\'liq ism' : 'Полное имя',
    'Yoshi' : 'Возраст',
    'Passport' : 'Паспорт',
    'Daraja' : 'Уровень',
    'Keshbek' : 'Кэшбэк',
    'O\'tib ketgan to\'lovingiz' : 'Ваш просроченный платеж',
    'To\'liq rassrochkalariz yopilishigacha' : 'До закрытия наших полных платежей',
    'To\'lov qilish' : 'Оплата',
    'bu nima ?' : 'что это ?',
    'qanday ishlataman?' : 'как мне использовать',
    'Rassrochkalar' : 'Рассрочка',
    'Faol' : 'Активный',
    'Tugagan' : 'Законченный',
    'Malum Rassrochkalar yo\'q' : 'Pассрочка неизвестна',
    'Ko\'proq' : 'Более',
    'Endi bozorga borishga hojat yo\'q' : 'Больше не нужно ходить на рынок',
    'Tez yetkazib berish' : 'Быстрая доставка',
    'Siz uchun qulayliklar' : 'Удобства для вас',
    'Bo\'lib to\'lash' : 'Рассрочка',
    'Bizda qulay narxlar va uyga yetkazib berish mavjud' : 'У нас доступные цены и доставка на дом',
    'Bizning xizmatimiz sizni ajablantiradi' : 'Наш сервис вас удивит',
    'Nosozlik yuzaga kelganda tez rasmiylashtirish va qaytarish kafolati' : 'Быстрое оформление и гарантия возврата в случае неисправности',
    '3, 6, 9 va 12 oy davomida oldindan to’lovsiz' : '3, 6, 9 и 12 месяцев без предоплаты',
    'Biz bilan aloqa' : 'Связаться с нами',
    'Erishgan yutuqlarimiz' : 'Наши достижения',
    'Holat' : 'Положение',
    'Miqdor' : 'Количество',
    'Qarz miqdori' : 'Сумма долга',
    'To`lov sanasi' : 'Дата выплаты',
    "Rassrochka" : 'Рассрочка ',
    'Kompaniya IDsi' : 'Компания ИД',
    'To\'lov tugashiga qoldi' : 'Платеж ожидает',
    'Aktni ko\'rish' : 'Посмотреть акт',
    'Holatni tanlang' : 'Выберите статус',
    'Mening kartalarim' : 'Мои карты',
    'Karta qo\'shish' : 'Добавить карту',
    'Profil' : 'Профиль',
    'Sanasi :' : 'Дата:',
    'To’lov ID :' : 'Платить ИД: ',
    'Umumiy :' : 'Общий',
    'To’langan :' : 'Оплаченный',
    'To’lanmagan :' : 'Не выплачивается',
    'Mahsulot :' : 'Продукт:',
    'Holati :' : 'Положение:',
    'To\'lov': 'Pay',
    'Kechirasiz, sahifa toplimadi!' : 'Извините, страница не найдена!',
    'Bosh sahifaga qaytish' : 'Вернуться на главную страницу',
    'Shartnoma :' : 'Контракты :',
    "Vaqti :" : 'Время :',
    "To'lov usuli :" : 'Cпособ оплаты :',
    'Summa :' : 'Количество:',
    'Tranzaksiya raqami :' : 'Номер транзакции :',
    'Ulashish' : 'Поделиция',
    "Biz ijtimoiy tarmoqlarda" : 'Мы в социальных сетях',
    'Ko\'p so\'raladigan savollar' : 'Часто задаваемые вопросы',
    'Qabul qilish punktlari' : 'Пункты выдачи',
    'Bizning brendlarimiz' : 'Наши бренды',
    'Ommaviy taklif (Foydalanuvchi shartnomasi)' : 'Публичная оферта (Пользовательское соглашение)',
    'Bo\'lib-bo\'lib xarid qilish uchun taklif' : 'Оферта для покупок в рассрочку',
    'Biz haqimizda' : 'О нас',
    'To\'lov turlari' : 'Виды оплаты',
    'Kitob do\'konlari' : 'Книжные магазины',
    'To\'liq rassrochkalaringiz yopilishigacha' : 'Пока не закроется твоя полная рассрочка',
    'so\'m qoldi': 'сум остался',
    'oy' : 'месяц',



}
export default translationRu;