import boxIcon from '../../../assets/img/icons/box.svg'
import deliveryIcon from '../../../assets/img/icons/truck.svg'
import foryouIcon from '../../../assets/img/icons/return.svg'
import cardIcon from '../../../assets/img/icons/card.svg'
import uzum from '../../../assets/img/payment/uzum.svg'
import humo from '../../../assets/img/payment/humo.svg'
import master from '../../../assets/img/payment/master.svg'
import visa from '../../../assets/img/payment/visa.svg'
import uzcard from '../../../assets/img/payment/uzcard.svg'
import payme from '../../../assets/img/payment/payme.svg'
import {FaFacebook, FaInstagram, FaLocationDot, FaPhone, FaTelegram, FaYoutube} from "react-icons/fa6";
import {IoMdMail} from "react-icons/io";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

const Footer = () => {
    const {t} = useTranslation()
    return (<>
            <section className="footer__top container-fluid">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-3 col-lg-6 col-md-12 card-icon">
                            <div>
                                <img src={boxIcon} alt=""/>
                            </div>
                            <div>
                                <p>{t("Endi bozorga borishga hojat yo\'q")}</p>
                                <p>{t("Bizda qulay narxlar va uyga yetkazib berish mavjud")}</p>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-12 card-icon">
                            <div><img src={deliveryIcon} alt=""/>
                            </div>
                            <div>
                                <p>{t("Tez yetkazib berish")}</p>
                                <p>{t("Bizning xizmatimiz sizni ajablantiradi")}</p>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-12 card-icon">
                            <div>
                                <img src={foryouIcon} alt=""/>
                            </div>
                            <div>
                                <p>
                                    {t("Siz uchun qulayliklar")}
                                </p>
                                <p>
                                    {t("Nosozlik yuzaga kelganda tez rasmiylashtirish va qaytarish kafolati")}
                                </p>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-12 card-icon">
                            <div>
                                <img src={cardIcon} alt=""/>
                            </div>
                            <div>
                                <p>
                                    {t("Bo\'lib to\'lash")}
                                </p>
                                <p>
                                    {t("3, 6, 9 va 12 oy davomida oldindan to’lovsiz")}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <footer>
                <nav className="bottom container-fluid">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-4 col-sm-12">
                                <p className="title">{t("Ma’lumotlar")}</p>
                                <ul>
                                    <li>
                                        <Link to="faq" className="bottom-link">{t("Ko\'p so\'raladigan savollar")}</Link>
                                    </li>
                                    <li>
                                        <a href="https://asaxiy.uz/page/delivery-points" target="_blank" className="bottom-link">{t("Qabul qilish punktlari")}</a>
                                    </li>
                                    <li>
                                        <a href="https://asaxiy.uz/product/brands" target="_blank" className="bottom-link">{t("Bizning brendlarimiz")}</a>
                                    </li>
                                    <li>
                                        <a href="https://asaxiy.uz/uz/page/rules" target="_blank" className="bottom-link">{t("Ommaviy taklif (Foydalanuvchi shartnomasi)")}</a>
                                    </li>
                                    <li>
                                        <a href="https://asaxiy.uz/page/oferta-rassrochka.html" target="_blank" className="bottom-link">{t("Bo'lib-bo'lib xarid qilish uchun taklif")}</a>
                                    </li>
                                    <li>
                                        <a href="https://asaxiy.uz/page/about" target="_blank" className="bottom-link">{t("Biz haqimizda")}</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-12">
                                <h1 className="title">
                                    {t("Biz bilan aloqa")}
                                </h1>
                                <ul>
                                    <li>
                                        <a href="tel:+998712990105" className="bottom-link"> <FaPhone/> +998 71 299 01
                                            05</a>
                                    </li>
                                    <li>
                                        <a href="mailto:info@asaxiy.uz" className="bottom-link">
                                            <IoMdMail/> info@asaxiy.uz</a>
                                    </li>
                                    <li>
                                        <a target='_blank' href="https://www.google.com/maps/place/%D0%A2%D0%B0%D1%88%D0%BA%D0%B5%D0%BD%D1%82%D1%81%D0%BA%D0%B8%D0%B9+%D0%93%D0%BE%D1%81%D1%83%D0%B4%D0%B0%D1%80%D1%81%D1%82%D0%B2%D0%B5%D0%BD%D0%BD%D1%8B%D0%B9+%D0%A3%D0%BD%D0%B8%D0%B2%D0%B5%D1%80%D1%81%D0%B8%D1%82%D0%B5%D1%82+%D0%AD%D0%BA%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D0%BA%D0%B8/@41.3108037,69.2304245,15z/data=!4m6!3m5!1s0x38ae8b05774c5969:0xd6f6eef4c05c280!8m2!3d41.3108047!4d69.2494792!16s%2Fm%2F0415l82?entry=ttu" className="bottom-link"> <FaLocationDot/> улица Ислама Каримова, 49, Ташкент</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-12">
                                <h1 className="title">
                                    {t("Erishgan yutuqlarimiz")}
                                </h1>
                                <ul>
                                    <li>
                                        <a target="_blanknpm start" href="https://kun.uz/news/2019/12/16/tahsin-mukofoti-goliblari-taqdirlandi?q=%2Fuz%2Fnews%2F2019%2F12%2F16%2Ftahsin-mukofoti-goliblari-taqdirlandi" className="bottom-link">"Tahsin" mukofoti</a>
                                    </li>
                                    <li>
                                        <a target="_blank" href="https://kun.uz/uz/40797306" className="bottom-link">"Shuhrat" medali</a>
                                    </li>
                                    <li>
                                        <a target="_blank" href="https://kun.uz/news/2021/06/30/foto-shavkat-mirziyoyev-faol-yoshlarni-orden-va-medallar-bilan-taqdirladi?q=%2Fuz%2Fnews%2F2021%2F06%2F30%2Ffoto-shavkat-mirziyoyev-faol-yoshlarni-orden-va-medallar-bilan-taqdirladi" className="bottom-link">"Kelajak bunyodkori" medali</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-12">
                                <h1 className="title">
                                    {t("Kitob do\'konlari")}
                                </h1>
                                <ul>
                                    <li>
                                        <a href="https://www.google.com/maps/place/Asaxiy+Books/@41.2927153,69.2218752,19.08z/data=!4m6!3m5!1s0x38ae8bd7244bb96f:0x8c305c45e87a4b89!8m2!3d41.2931869!4d69.222!16s%2Fg%2F11llwv0dlw?coh=164777&entry=tt&shorturl=1" target="_blank" className="bottom-link"><FaLocationDot/> Asaxiy Novza</a>
                                    </li>
                                    <li>
                                        <a href="https://www.google.com/maps/place/Asaxiy+Books+Farhod+bozori/@41.2870131,69.1874208,20.12z/data=!4m5!3m4!1s0x38ae89f983a69edd:0x427c98bf5dbae658!8m2!3d41.2871646!4d69.1872334?coh=164777&entry=tt&shorturl=1" target="_blank" className="bottom-link"><FaLocationDot/> Asaxiy Farhod bozori</a>
                                    </li>
                                    <li>
                                        <a href="https://www.google.com/maps/place/Asaxiy+Panorama/@41.3202186,69.2219002,13z/data=!4m7!3m6!1s0x38ae8bf0ed869145:0xbe73068be88f7a95!8m2!3d41.3203705!4d69.2621419!15sCgZBc2F4aXlaCCIGYXNheGl5kgEKYm9va19zdG9yZeABAA!16s%2Fg%2F11tw_smyr0?coh=164777&entry=tt&shorturl=1" target="_blank" className="bottom-link"><FaLocationDot/> Asaxiy Panorama</a>
                                    </li>
                                    <li>
                                        <a href="https://www.google.com/maps/place/GroundZero+Kitob+Olami+coworking/@42.5290277,67.2981913,7z/data=!4m6!3m5!1s0x38aef5263f4151f9:0xc0f7c44c6fa654ee!8m2!3d41.3149333!4d69.2892947!16s%2Fg%2F11f8l7ry_p?entry=tts&shorturl=1" target="_blank" className="bottom-link"><FaLocationDot/> Asaxiy Kitob Olami</a>
                                    </li>
                                    <li>
                                        <a href="https://www.google.com/maps/place/Asaxiy+Books+Beruniy+filiali/@41.3175349,69.1970623,13z/data=!4m7!3m6!1s0x38ae8dfcf880b84d:0x6f8fbb63df873935!8m2!3d41.3479198!4d69.2151197!15sCgZhc2F4aXlaCCIGYXNheGl5kgEKYm9va19zdG9yZeABAA!16s%2Fg%2F11y55tr35g?coh=219816&entry=tts&g_ep=EgoyMDI0MDYxMi4wKgBIAVAD" target="_blank" className="bottom-link"><FaLocationDot/> Asaxiy Beruniy</a>
                                    </li>
                                    <li>
                                        <a href="https://www.google.com/maps/search/41.303436,+69.233737?entry=tts" target="_blank" className="bottom-link"><FaLocationDot/> Asaxiy Books Kamolon</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-12 mt-1">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-md-12 col-lg-4">
                                        <div
                                            className="footer__socials mb-4 mb-lg-0 d-flex justify-content-between flex-column h-100">
                                            <h1 className="title">
                                                {t("Biz ijtimoiy tarmoqlarda")}
                                            </h1>
                                            <ul className="row g-3 social-icons">
                                                <li className="col-auto"><a href="https://www.facebook.com/asaxiyshop/" target="_blank" className="icon">
                                                    <FaFacebook/>
                                                </a>

                                                </li>
                                                <li className="col-auto">
                                                    <a href="https://t.me/asaxiyuz" target="_blank" className="icon">
                                                        <FaTelegram/>
                                                    </a>

                                                </li>
                                                <li className="col-auto">
                                                    <a href="https://www.instagram.com/asaxiyshop/" target="_blank" className="icon">
                                                        <FaInstagram/>
                                                    </a>

                                                </li>
                                                <li className="col-auto">
                                                    <a href="https://www.youtube.com/channel/UC0UaPm4AlSkAU7U88H8jhhQ" target="_blank" className="icon">
                                                        <FaYoutube/>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-lg-7 ">
                                        <p className="title">{t("To\'lov turlari")}</p>
                                        <div className="row g-3 pay-group">
                                            <div className="col-6 col-sm-4 col-lg-2 ">
                                                <div
                                                    className="payment__item d-flex justify-content-center align-items-center">
                                                    <img src={uzum} className="lazyload"
                                                         loading="lazy"
                                                         width="69"
                                                         height="20" alt="uzum"/>
                                                </div>
                                            </div>
                                            <div className="col-6 col-sm-4 col-lg-2 ">
                                                <div
                                                    className="payment__item d-flex justify-content-center align-items-center">
                                                    <img src={payme} className="lazyload"
                                                         loading="lazy"
                                                         width="68"
                                                         height="20" alt="payme"/>
                                                </div>
                                            </div>
                                            <div className="col-6 col-sm-4 col-lg-2 ">
                                                <div
                                                    className="payment__item d-flex justify-content-center align-items-center">
                                                    <img src={visa} className="lazyload"
                                                         loading="lazy"
                                                         width="62"
                                                         height="21" alt="visa"/>
                                                </div>
                                            </div>
                                            <div className="col-6 col-sm-4 col-lg-2 ">
                                                <div
                                                    className="payment__item d-flex justify-content-center align-items-center">
                                                    <img src={master}
                                                         className="lazyload" loading="lazy"
                                                         width="36" height="28" alt="mastercard"/>
                                                </div>
                                            </div>
                                            <div className="col-6 col-sm-4 col-lg-2 ">
                                                <div
                                                    className="payment__item d-flex justify-content-center align-items-center">
                                                    <img src={humo} className="lazyload"
                                                         loading="lazy"
                                                         width="46"
                                                         height="28" alt="humo"/>
                                                </div>
                                            </div>
                                            <div className="col-6 col-sm-4 col-lg-2 ">
                                                <div
                                                    className="payment__item d-flex justify-content-center align-items-center">
                                                    <img src={uzcard}
                                                         className="lazyload" loading="lazy"
                                                         width="21"
                                                         height="32" alt="uzcard"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="border-top mt-5 py-3">
                            <p>2015-2024 Internet-do’kon asaxiy.uz: Maishiy texnikalar va boshqalar.Mahsulotni yetkazib
                                berish barcha viloyatlarda amalga oshiriladi. Barcha huquqlar himoyalangan.</p>
                        </div>
                    </div>
                </nav>
            </footer>
        </>)
}

export default Footer