import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import './assets/styles/global.scss'
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Layout from "./components/layout/Layout";
import Home from "./components/pages/home/Home";
import MyId from "./components/pages/auth/myId/MyId";
import Contracts from "./components/pages/contract/Contracts";
import Error from "./components/pages/error/Error";
import Details from "./components/pages/contract/details/Details";
import News from "./components/pages/news/News";
import Cards from "./components/pages/card/Cards";
import NewSingle from "./components/pages/news/news-view/New-single";
import Monitoring from './components/pages/monitoring/Monitoring';
import Akt from "./components/pages/akt/Akt";
import Data from "./components/pages/data/Data";
import Faq from './components/pages/faq/Faq';


const router = createBrowserRouter([
    {
        path: "/",
        element: <Layout/>,
        children: [
            {
                path: '/',
                element: <Home/>
            },
            {
                path: 'contracts',
                element: <Contracts/>,
            },
            {
                path: 'contracts/:orderId',
                element: <Details/>
            },
            {
                path: 'cards',
                element: <Cards/>
            },
            {
                path: 'faq',
                element: <Faq />
            },
            {
                path: 'news',
                element: <News/>,
            },
            {
                path: 'news/:singleId',
                element: <NewSingle/>,
            },
            {
                path: "monitoring",
                element: <Monitoring />
            },
            {
                path: '*',
                element: <Error/>
            },
            // {
            //     path: 'data',
            //     element: <Data/>
            // }
        ]
    },
    {
        path: "akt/:aktId",
        element: <Akt />
    },
    {
        path: 'register',
        element: <MyId/>
    }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <RouterProvider router={router}/>
);

reportWebVitals();
