import {CiSquarePlus} from "react-icons/ci";
import {RiDeleteBin6Line} from "react-icons/ri";
import {IoSettingsOutline} from "react-icons/io5";
import {useEffect, useState} from "react";
import {bindCard, bundCard, getCardList, verifyCardBind} from "../../utils/api/getUserData";
import humo from '../../../assets/img/icons/humo.svg'
import uzcard from '../../../assets/img/icons/uzcard.svg'
import visa from '../../../assets/img/icons/visa.svg'
import logo from '../../../assets/img/bankLogo.png'
import {useTranslation} from "react-i18next";
import error from "../error/Error";

const Cards = () => {
    const [cardsList, setCardsList] = useState()
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        getCardList().then(data => setCardsList(data)).catch(error => console.error(error)).finally(() => setLoaded(true))
    }, []);

    const [cardNumber, setCardNumber] = useState('');
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');

    const currentYear = new Date().getFullYear();
    const minYear = (currentYear - 3) % 100;
    const maxYear = minYear + 13;
    const {t} = useTranslation()

    const handleCardNumberChange = (e) => {
        let input = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
        input = input.slice(0, 16); // Limit to 16 digits
        const formattedInput = input.replace(/(.{4})/g, '$1 ').trim(); // Add space after every 4 digits
        setCardNumber(formattedInput);
    };

    const handleMonthChange = (e) => {
        let input = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
        // if (input) {
        //     let month = parseInt(input, 10);
        //     if (month < 1) month = 1;
        //     if (month > 12) month = 12;
        //     input = month.toString().padStart(2, '0'); // Ensure 2-digit format
        // }
        setMonth(input);
    };

    const handleYearChange = (e) => {
        let input = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
        // if (input) {
        //     let year = parseInt(input, 10);
        //     if (year < minYear) year = minYear;
        //     if (year > maxYear) year = maxYear;
        //     input = year.toString().padStart(2, '0'); // Ensure 2-digit format
        // }
        setYear(input);
    };

    const [cardBind, setCardBind] = useState(0)
    const [cardApiMessage, setCardApiMessage] = useState(false)
    const [otpSent, setOtpSent] = useState()
    const [otpStatus, setOtpStatus] = useState()

    const handleCheckCard = () => {
        bindCard(cardNumber.replace(/\s/g, ''), `${year}${month}`).then(data => {
            setCardApiMessage(data)
            setCardBind(data.data.status)
        }).catch(error => console.log(error))
    }

    const handleVerifyCardBind = () => {
        verifyCardBind(cardApiMessage.data.data.id, otpSent).then(data => setOtpStatus(data)).catch(error => console.error(error))
    }

    useEffect(() => {
        console.log(otpStatus)
    }, [otpStatus])

    useEffect(() => {
        console.log(cardApiMessage)
    }, [cardApiMessage])

    const displayedCardNumber = cardNumber || '**** **** **** ****';
    const displayedMonth = month || 'MM';
    const displayedYear = year || 'YY';

    return (

        <section className="cards container-fluid py-4">


            <div className="container">
                <span className="cards__title mb-4">
                    {t("Mening kartalarim")}
                    <button className="btn btn-success" type='button' data-bs-toggle="modal"
                            data-bs-target="#addCard">{t('Karta qo\'shish')} <CiSquarePlus/></button>
                </span>
                {/* Modals start */}
                <div className="modal fade" id="addCard" tabIndex="-1"
                     aria-labelledby="addCard" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body pt-4">
                                <div className="cover custom rounded">
                                    <div className="cover-top">
                                        <img src={logo} alt=""/>
                                        <img src={humo} alt=""/>
                                    </div>
                                    <div className="cover-bottom">
                                        <div className="data">
                                            <p className='fullname m-0'>Isim Familiya</p>
                                            <span className="number">{displayedCardNumber}</span>
                                        </div>
                                        <span className="expire">{displayedMonth}/{displayedYear}</span>
                                    </div>
                                </div>
                                {cardBind ? (<div className="card__verify-side">
                                    <span>Karta raqamini kiriting</span>
                                    <div className="input-group mt-1 mb-3">
                                        <input type="number" placeholder='0000' value={otpSent} onChange={(e) => setOtpSent(e.target.value)} className="form-control"/>
                                    </div>
                                    <div className="btn-group w-100">
                                        <button type="button" className="btn btn-primary" onClick={() => handleVerifyCardBind()}>
                                            Saqlash
                                        </button>
                                    </div>
                                </div>) : (<div className='card__add-side'>
                                        <span>Karta raqamini kiriting</span>
                                        <div className="input-group mb-3 mt-1">
                                            <input
                                                type="text"
                                                placeholder="4444 4444 4444 4444"
                                                className="form-control"
                                                value={cardNumber}
                                                onChange={handleCardNumberChange}
                                            />
                                        </div>
                                        <span>Muddatini kiriting</span>
                                        <div className="input-group mb-3 mt-1" style={{gap: '1rem'}}>
                                            <input
                                                type="text"
                                                placeholder="MM"
                                                className="form-control rounded"
                                                value={month}
                                                onChange={handleMonthChange}
                                            />
                                            <input
                                                type="text"
                                                placeholder="YY"
                                                className="form-control rounded"
                                                value={year}
                                                onChange={handleYearChange}
                                            />
                                        </div>
                                        <div className="btn-group w-100">
                                            <button type="button" className="btn btn-primary"
                                                    onClick={() => handleCheckCard()}>Tastiqlash
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {/* Modals end */}

                {loaded ? (<div className="cards-group">
                    {cardsList && cardsList.length > 0 ? (cardsList.map(card => (<div key={card.id}
                                                                                      className={`card ${card.card_type == 'HUMO' ? 'humo' : card.card_type == 'UZCARD' ? 'uzcard' : card.card_type == 'Viza' ? 'visa' : 'humo'} border-0 p-3`}>
                        <div className="card__top">
                            <div className="balance"><span>{card.balance}</span> <span>uzs</span></div>
                            <div className="logo">
                                <span>{card.card_type == 'HUMO' ? (
                                    <img src={humo} alt=""/>) : card.card_type == 'UZCARD' ? (
                                    <img src={uzcard} alt=""/>) : card.card_type == 'Viza' ? (
                                    <img src={visa} alt=""/>) : 'Humo'}</span>
                            </div>
                        </div>
                        <div className="card__bottom">
                            <div className="info">
                                <div className="number">{card.card_number}</div>
                                <div className="fullname">{card.card_holder}</div>
                            </div>
                            <div className="expire">
                                <span>{card.expire_date}</span>
                            </div>
                        </div>
                    </div>))) : (<div>
                        <h1>Info not found</h1>
                    </div>)}
                </div>) : (<div className='container loader-wrapper h-100 d-flex'>
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>)}
            </div>
        </section>)
}

export default Cards