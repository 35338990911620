import axios from "./axios";
import {getCookie} from "./cookie";

const access = getCookie('access_token')

export const getUserInfo = () => {
    return axios.get('/profile/me', {
        headers: {
            'Authorization': `Bearer ${access}`
        }
    })
        .then(response => {
            return {
                ...response.data.data
            };
        })
        .catch(error => {
            console.error(error);
        });
}


export const getCardList = () => {
    return axios.get('/card/list', {
        headers: {
            'Authorization': `Bearer ${access}`
        }
    })
        .then(response => {
            return response.data.data;
        })
        .catch(error => {
            console.error(error);
        });
}

// Card bind
export const bindCard = (number, expire) => {
    return axios.post('/card/create', {
            number: `${number}`,
            cv: `${expire}`
        }, {
            headers: {
                'Authorization': `Bearer ${access}`
            }
        }
    )
        .then(response => {
            return response;
        })
        .catch(error => {
            console.error(error);
        });
}

export const verifyCardBind = (cardId, password) => {
    return axios.post('/card/apply-binding', {
            card_id: `${cardId}`,
            password: `${password}`
        }, {
            headers: {
                'Authorization': `Bearer ${access}`
            }
        }
    )
        .then(response => {
            return response;
        })
        .catch(error => {
            console.error(error);
        });
}
// Function for get detailed data about status for order
export const getOrderStatus = () => {
    return axios.get('/order/status-list', {
        headers: {
            'Authorization': `Bearer ${access}`
        }
    })
        .then(response => {
            return {
                ...response.data.data
            };
        })
        .catch(error => {
            console.error(error);
        });
}

export const getOrderList = (maxItem) => {
    return axios.get(`/order/list?page=1&pageSize=${maxItem ? maxItem : 20}`, {
        headers: {
            'Authorization': `Bearer ${access}`
        }
    })
        .then(response => {
            return {
                ...response.data
            };
        })
        .catch(error => {
            console.error(error);
        });
}

export const getOrderSingle = (orderId) => {
    return axios.get(`/order/single?id=${orderId}`, {
        headers: {
            'Authorization': `Bearer ${access}`
        }
    })
        .then(response => {
            return {
                ...response.data.data
            };
        })
        .catch(error => {
            console.error(error);
        });
}

export const getAllNews = (page = 1, pageSize = 20) => {
    return axios.get(`/news/list?page=${page}&page_size=${pageSize}`, {
        headers: {
            'Authorization': `Bearer ${access}`
        }
    })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            console.error(error);
        });
};


export const getNewSingle = (singleId) => {
    return axios.get(`/news/single?id=${singleId}`, {
        headers: {
            'Authorization': `Bearer ${access}`
        }
    })
        .then(response => {
            return {
                ...response.data.data
            };
        })
        .catch(error => {
            console.error(error);
        });
}

export const getTransactions = (page = 1, pageSize = 20) => {
    return axios.get(`/transaction/list?page=${page}&pageSize=${pageSize}`, {
        headers: {
            'Authorization': `Bearer ${access}`
        }
    })
        .then(response => {
            return response.data.data;
        })
        .catch(error => {
            console.error(error);
        });
};


export const getTransactionsSingle = (transactionId) => {
    return axios.get(`/transaction/single?id=${transactionId}`, {
        headers: {
            'Authorization': `Bearer ${access}`
        }
    })
        .then(response => {
            return {
                ...response.data.data
            };
        })
        .catch(error => {
            console.error(error);
        });
}
export const getMyDegree = (cardId, password) => {
    return axios.post('/profile/my-degree', {
            card_id: `${cardId}`,
            password: `${password}`
        }, {
            headers: {
                'Authorization': `Bearer ${access}`
            }
        }
    )
        .then(response => {
            return response.data.data.next_degree_data;
        })
        .catch(error => {
            console.error(error);
        });
}
